import StyledCard from "../Card";
import React from "react";
import styles from "../../styles/PreAuth/Callout"

function Callout({children, customStyles, elevation = 2,}){
    return(<StyledCard elevation={elevation} styles={{...styles, ...customStyles}}>
        {children}
    </StyledCard>)
}

export default Callout