import React from "react";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import DefaultTableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function TableHead() {
  const SubmissionTableHead = styled(DefaultTableHead)(() => {
    return {
      visibility: "hidden",
      width: "1px",
      height: "1px",
      position: "absolute",
      overflow: "hidden",
      left: "-10000px",
      top: "10000px",
    };
  });

  return (
    <SubmissionTableHead>
      <TableRow>
        <TableCell>Property</TableCell>
        <TableCell>Value</TableCell>
      </TableRow>
    </SubmissionTableHead>
  );
}

export default TableHead;
