import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SubmissionCard from "./SubmissionsCard/SubmissionCard";

function Section({ title, subtext, submissions }) {
  return (
    <Box sx={{ marginBottom: "75px", "&:last-child": { marginBottom: "0" } }}>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body1">{subtext}</Typography>
      <Grid
        container
        columns={{xs: 1, md:2, lg: 3}}
        spacing={2}
        alignItems={"flex-start"}
        // justifyContent={"space-evenly"}
        sx={{
          margin: "15px 0 0",
        }}
      >
        {submissions &&
          submissions.map((submission) => {
            const {
              uuid,
              title,
              publication,
              createdAt,
              updatedAt,
              organization,
              category,
              payment,
              specialConsideration,
              errorCount,
              submitted,
            } = submission;

            return (
              <Grid item xs={1} key={`submission-${uuid}`}>
                <SubmissionCard
                  id={uuid}
                  title={title}
                  publication={publication}
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                  organization={organization}
                  payment={payment}
                  specialConsideration={specialConsideration}
                  errorCount={errorCount}
                  submitted={submitted}
                  category={category && category.title} //TODO: include FR
                />
              </Grid>
            );
          })}

          {submissions && submissions.length === 0 && <Typography variant={'body2'}>(No submissions found)</Typography>}
      </Grid>
    </Box>
  );
}

export default Section;
