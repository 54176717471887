import React from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";

import SideBarLayout from "../components/PostAuth/SideBarLayout";
import NotFound from "../components/Submissions/NotFound";

import useSubmissionData from "../hooks/useSubmissionData";

import Callout from "../components/Callout/Callout";
import useLocale from "../hooks/useLocale";
import { useTranslation, Trans } from "react-i18next";
import { Grid } from "@mui/material";

function Invoices() {
  const [notFound] = React.useState(false);
  const [sideBar] = React.useState();
  const submissionData = useSubmissionData();
  const { locale } = useLocale();
  const { t } = useTranslation();

  const { submissions, loading: loadingSubmissions, updateSubmissions } = submissionData;

  const buttons = [];


  React.useEffect(()=>{
    updateSubmissions(()=>{
      //TODO: Handle loading?
    })
  },[])

  if (notFound) return <NotFound />;
  return (
    <SideBarLayout
      title={t("invoices.title")}
      subtext={t("invoices.subtext")}
      buttons={buttons}
      sideBar={sideBar}
      body={
        <>
          <TableContainer sx={{ mb: "50px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("invoices.body.table.head.title")}</TableCell>
                  <TableCell>
                    {t("invoices.body.table.head.publication")}
                  </TableCell>
                  <TableCell>
                    {t("invoices.body.table.head.category")}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {t("invoices.body.table.head.invoice")}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {t("invoices.body.table.head.summary")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submissions &&
                  submissions
                    .filter(
                      (submission) =>
                        submission.invoice &&
                        typeof submission.invoice !== "undefined"
                    )
                    .map((submission) => {
                      return (
                        <TableRow key={submission.uuid}>
                          <TableCell>
                            {submission.title ||
                              t("invoices.body.table.head.noTtle")}
                          </TableCell>
                          <TableCell>
                            {submission.publication ||
                              t("invoices.body.table.head.noPublication")}
                          </TableCell>
                          <TableCell>
                            {(submission.category &&
                              submission.category.title) ||
                              t("invoices.body.table.head.noCategory")}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {submission.invoice.pdf_link ? (
                              <a
                                href={submission.invoice.pdf_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <PictureAsPdf sx={{ mb: "-6px" }} />{" "}
                                {t("invoices.body.table.body.download")}
                              </a>
                            ) : (
                              t("invoices.body.table.head.noInvoice")
                            )}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {submission.invoice.summary_link ? (
                              <a
                                href={submission.invoice.summary_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <PictureAsPdf sx={{ mb: "-6px" }} />{" "}
                                {t("invoices.body.table.body.download")}
                              </a>
                            ) : (
                              t("invoices.body.table.head.noSummary")
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}

                {!loadingSubmissions && !submissions && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Callout elevation={0}>
                        <Typography variant={"body2"}>
                          <strong>
                            {t("invoices.body.noInvoice.heading")}
                          </strong>
                        </Typography>
                        <Typography variant={"body2"}>
                          <Trans i18nKey={"invoices.body.noInvoice.body"}>
                            Once you submit and pay for a submission, your
                            invoices will appear here.{" "}
                            <Link to={`/${locale}/your-submissions`}>
                              Go back to your submissions.
                            </Link>
                          </Trans>
                        </Typography>
                      </Callout>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Callout elevation={0}>
            <Typography variant={"body2"} gutterBottom>
              <strong>{t("invoices.body.callout.title")}</strong>
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={7}>
                <Typography variant={"body2"} gutterBottom>
                  {t("invoices.body.callout.leftCol.line1")}
                </Typography>
                <Typography variant={"body2"} gutterBottom>
                  {t("invoices.body.callout.leftCol.line2")}
                </Typography>
                <Typography variant={"body2"} gutterBottom>
                  {t("invoices.body.callout.leftCol.line3")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Trans i18nKey={"invoices.body.callout.body.rightCol"}>
                <Typography variant={"body2"} gutterBottom>
                  {t("invoices.body.callout.rightCol.line1")}
                </Typography>
                <Typography variant={"body2"} gutterBottom>
                  {t("invoices.body.callout.rightCol.line2")}
                </Typography>
                <Typography variant={"body2"} gutterBottom>
                  {t("invoices.body.callout.rightCol.line3")}
                </Typography>
                <Typography variant={"body2"} gutterBottom>
                  {t("invoices.body.callout.rightCol.line4")}
                </Typography>
                  
                </Trans>
              </Grid>
            </Grid>
          </Callout>
        </>
      }
      success={null}
      failure={<p>Something went wrong</p>}
    />
  );
}

export default Invoices;
