import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import MainBodyHeader from "./MainBodyHeader";
import MainBodyFooter from "./MainBodyFooter";

function MainBody({ title, subtext, buttons, children, success, failure }) {
  //TODO: Move styles to global
  return (
    <Paper
      sx={{
        p: "25px",
        mb: '20px',
      }}
    >
      <Box>
        <MainBodyHeader title={title} subtext={subtext} buttons={buttons.filter((b)=>(b.type!=='paypal'))} />
        {children && children}
        {success && success}
        <MainBodyFooter buttons={buttons} />
      </Box>
    </Paper>
  );
}

export default MainBody;
