import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "../../../styles/PreAuth/TwoColumnBody/TitleBar";

function TitleBar({ title }) {
  return (
    <Box sx={styles}>
      <Typography variant="h1">{title}</Typography>
    </Box>
  );
}

export default TitleBar;
