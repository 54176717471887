import React from "react";

import SubmissionDetailsForm from "./SubmissionDetailsForm";
import SubmissionInfo from "./SubmissionInfo";
import constants from "../../utils/constants";

function Body({ step, submission, categories, disabled, onChange }) {
  if (step === constants.SUBMISSION_STEP.INFO) {
    return (
      <SubmissionInfo
        submission={submission}
        categories={categories}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (step === constants.SUBMISSION_STEP.DETAILS) {
    return (
      <SubmissionDetailsForm
        submission={submission}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (step === constants.SUBMISSION_STEP.LOADING) {
    return <>Please wait...</>;
  }

  return <></>;
}

export default Body;
