import { client } from "./client";

const authService = {
  getUser() {
    return JSON.parse(window.localStorage.getItem("auth"));
  },
  login(data, callback) {
    client
      .post("/auth/login/submitter", data)
      .then((res) => {
        if (res.status === 200) callback({ ...res.data, success: true });
        else res({ error: true, message: "" });
        return;
      })
      .catch((err) => {
        callback({ error: true, message: "" });
        return;
      });
  },

  logout(callback) {
    try {
      client.get("/auth/logout").then((res) => {
        if (res.status === 200) callback(true);
        else callback(false);
        return;
      });
    } catch (e) {
    }
  },
  async refresh() {
    const refresh = await client.get("/auth/refresh");
    if (refresh.status === 200) {
      return await this.setAuth({
        token: refresh.token,
        expiry: refresh.expiry,
      });
    }
    throw new Error("Could not refresh");
  },
  register(data, callback) {
    client
      .post(`/auth/register/submitter`, { data })
      .then((res) => {
        if (res.status === 200) {
          callback(res.data);
        }
      })
      .catch((e) => {
        return false;
      });
  },
  async isAuthenticated() {
    const req = await client.get("/auth/check");
    if (req.status === 200) return req.data.check;
    return false;
  },
  subscribeUser(callback) {
    this.setUser = callback;
    return () => {
      this.setUser = undefined;
    };
  },
  saveUserToLocalStore(user) {
    if (user) {
      localStorage.setItem("auth", JSON.stringify(user));
    }
  },
  removeUserFromLocalStore() {
    window.localStorage.removeItem("auth");
  },
  async setAuth({ token, expiry }) {
    this.user = { token, expiry };
    this.saveUserToLocalStore(this.user);
    if (this.setUser) {
      this.setUser(this.user);
    }
  },

  resetPassword({ email, lang }, callback) {
    try {
      client
        .post("/auth/password/reset", {
          email,
          lang,
        })
        .then((res) => {
          callback(!!(res && res.data.success));
        });
    } catch (e) {
      return false;
    }
  },

  changePassword({ token, password  }, callback) {
    try {
      client
        .post("/auth/password/change", {
          token,
          password,
        })
        .then((res) => {
          callback(!!(res && res.data.success));
        });
    } catch (e) {
      return false;
    }
  },
};

export { authService };
