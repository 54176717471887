import React from "react";
import TextField from "../Input/TextField";
import formUtils from "../../utils/forms";
import { useTranslation } from "react-i18next";

function ChangePasswordForm() {
  const {t} = useTranslation();
  const [formData, setFormData] = React.useState({
    password: {
      id: "password",
      name: "password",
      onChange: (e) =>
        formUtils.updateFormValue("password", e.target.value, setFormData),
      value: "",
      type: "password",
      label: t('changePassword.form.password.label'),
      subtext:  t('changePassword.form.password.subtext'),
      placeholder:  t('changePassword.form.password.placeholder'),
      error: "",
      required: true,
    },
    repeatPassword: {
      id: "repeatPassword",
      name: "repeatPassword",
      onChange: (e) =>
        formUtils.updateFormValue(
          "repeatPassword",
          e.target.value,
          setFormData
        ),
      value: "",
      type: "password",
      label: t('changePassword.form.password.label'),
      placeholder:  t('changePassword.form.password.placeholder'),
      error: "",
      required: true,
    },
  });

  React.useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      password: {
        ...prev.password,
        label: t("changePassword.form.password.label"),
        placeholder: t("changePassword.form.password.placeholder"),
      },
      repeatPassword: {
        ...prev.repeatPassword,
        label: t("changePassword.form.repeatPassword.label"),
        placeholder: t("changePassword.form.repeatPassword.placeholder"),
      },
    }));
  }, [t]);

  React.useEffect(() => {}, [formData]);

  return {
    formData,
    setFormData,
    Form: (
      <>
        <TextField {...formData.password} />
        <TextField {...formData.repeatPassword} error={formData.password.error} hideErrorLabel={true} />
      </>
    ),
  };
}

export default ChangePasswordForm;
