import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import LogoutButton from "./LogoutButton";
import useLogo from "../../hooks/useLogo";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

import mq from "../../theme/breakpoints";
import useLocale from "../../hooks/useLocale";
import { useTranslation } from "react-i18next";
import Footer from "../Common/Footer";

const Logo = styled.img({
  width: "150px",
  [mq.md]: {
    width: "100%",
  },
});

const NavLink = styled(Link)(() => ({
  textDecoration: "none",
  ".nav-underbar": {
    width: 0,
    height: "2px",
    transition: "all 0.2s ease-out",
    background: "rgba(0,0,0,0.60)",
    // margin: '0 auto',
    margin: "0 0 0 auto",
  },
  "&:hover .nav-underbar": {
    width: "0",
    [mq.md]: {
      width: "100%",
    },
  },
}));

// const NavSeperator = styled.hr({
//   width: '100%',
//   margin: '15px 0',
//   borderColor: 'rgba(190, 190, 190, 0.75)'
// })

function NavItem(props) {
  const { children, to, onClick } = props;
  return (
    <Grid
      item
      xs
      sx={{
        m: "10px 0",
        textAlign: {
          xs: "center",
          md: "left",
        },
      }}
    >
      <NavLink to={to} onClick={onClick}>
        <Typography variant={"navigation"}>{children}</Typography>
        <div className={"nav-underbar"} />
      </NavLink>
    </Grid>
  );
}

function NavBar({onNavLinkClick}) {
  const logo = useLogo();
  const {locale} = useLocale();
  const {t} = useTranslation();


  return (
    <Box sx={{ height: "100vh", position: "static" }}>
      <Grid
        container
        direction={"column"}
        justifyContent={"flex-start"}
        // alignItems={"stretch"}
        sx={{ height: "calc(100% - 40px)" }}
      >
        <Grid item md={1}>
          <Logo src={logo} />
        </Grid>
        <Grid
          item
          md
          sx={{
            mt: {
              xs: "25px",
              md: "50px",
            },
          }}
        >
          <Grid container direction={"column"}>
            <NavItem onClick={onNavLinkClick} to={`/${locale}/submission/new`}>{t('navbar.createSubmission')}</NavItem>
            {/* <NavSeperator/> */}
            <NavItem onClick={onNavLinkClick} to={`/${locale}/your-submissions`}>{t('navbar.yourSubmissions')}</NavItem>
            <NavItem onClick={onNavLinkClick} to={`/${locale}/invoices`}>{t('navbar.invoices')}</NavItem>
            {/* <NavItem onClick={onNavLinkClick} to={`/${locale}/account`}>Account</NavItem> */}
          </Grid>
        </Grid>
        <Grid
          item
          md={1}
          sx={{
            textAlign: { xs: "center", md: "left" },
            mt: {
              xs: '15px',
              md: '0'
            }
          }}
        >
          <LogoutButton />
          <Footer align="left" noPadding/>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NavBar;
