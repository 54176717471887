import { useState, useEffect } from "react";
import axios from "axios";
import { client } from "../services/client";

function useSignedUrl() {
  const [data, setData] = useState({
    signedUrl: "",
    file: null,
  });
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });

  useEffect(() => {
    function uploadToSignedUrl({ signedUrl, file, callback }) {
      setStatus((prev) => ({ ...prev, loading: true }));

      axios
        .put(signedUrl, file, {
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setStatus({ loading: false, success: true, error: null });
            callback();
          } else
            setStatus({
              loading: false,
              success: true,
              error: "Could not upload your image.",
            });
        })
        .catch((e) => {
          setStatus({
            loading: false,
            success: false,
            error: "Upload error: " + e.message,
          });
        });
    }
    if (data.signedUrl && data.file && !status.success && !status.error)
      uploadToSignedUrl(data);
  }, [data]);

  function upload(signedUrl, file, callback) {
    if (signedUrl && file) {
      setData({
        signedUrl,
        file,
        callback,
      });
    }
  }

  function getSignedUploadUrl(fileName, type, callback) {
    client
      .get("/awards/upload/url", {
        params: { fileName, type },
      })
      .then((res) => {
        if (res.status === 200) callback(res.data);
        return;
      })
      .catch((e) => {
        return false;
      });
  }

  return {
    ...data.file,
    ...status,
    upload,
    getSignedUploadUrl,
  };
}

export default useSignedUrl;
