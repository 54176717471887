const store = {
  auth: "nmaf_auth",
  cart: "nmaf_cart",
};

function checkStorage(name) {
  return JSON.parse(window.localStorage.getItem(store[name]));
}

function removeFromStorage(name) {
  window.localStorage.removeItem(store[name]);
}

function saveToStorage(name, value) {
  window.localStorage.setItem(store[name], value);
}



function checkTokenExpiry(data) {
  const { expiry } = data;
  if (Date.now() > expiry * 1000) {
    const expiryDate = new Date(0);
    expiryDate.setUTCSeconds(expiry);
    return false;
  } else return true;
}

function findAuth() {
  const data = checkStorage("auth");
  if (data && checkTokenExpiry(data)) {
    return data.token;
  } else return null;
}

function saveAuth(value) {
  saveToStorage("auth", JSON.stringify(value));
}

function deleteAuth() {
  removeFromStorage("auth");
}

function findCart() {
  const data = checkStorage("cart");
  return data
}
function saveCart(value) {
  saveToStorage("cart", JSON.stringify(value));
}

function compareCart(to){
  const localCart = findCart()
  return JSON.stringify(localCart) === JSON.stringify(to)
}

export default {
  checkStorage,
  removeFromStorage,
  saveToStorage,
  saveAuth,
  findAuth,
  deleteAuth,
  findCart,
  compareCart,
  saveCart,
};
