import React from "react";
import TextField from "../Input/TextField";
import { useTranslation, Trans } from "react-i18next";

function LoginForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = React.useState({
    email: {
      id: "email",
      name: "email",
      onChange: (e) => {
        setFormData((prev) => ({
          ...prev,
          email: {
            ...prev.email,
            value: e.target.value,
          },
        }));
      },
      value: "",
      type: "text",
      label: t("login.form.email.label"),
      placeholder: t("login.form.email.placeholder"),
      error: "",
      required: true,
    },
    password: {
      id: "password",
      name: "password",
      onChange: (e) => {
        setFormData((prev) => ({
          ...prev,
          password: {
            ...prev.password,
            value: e.target.value,
          },
        }));
      },
      value: "",
      type: "password",
      label: t("login.form.password.label"),
      //t("login.form.password.label")
      placeholder: <Trans i18nKey={"login.form.password.label"}>•••••••••</Trans>,
      error: "",
      required: true,
    },
  });

  React.useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      email: {
        ...prev.email,
        label: t("login.form.email.label"),
        placeholder: t("login.form.email.placeholder"),
      },
      password: {
        ...prev.password,
        label: t("login.form.password.label"),
        placeholder: t("login.form.password.placeholder"),
      },
    }));
  }, [t]);

  React.useEffect(() => {}, [formData]);

  return {
    formData,
    setFormData,
    Form: (
      <>
        <TextField {...formData.email} />
        <TextField {...formData.password} />
      </>
    ),
  };
}

export default LoginForm;
