import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import NavBar from "./NavBar";
import SubmissionsProvider from "../../providers/submissions";
import CartProvider from "../../providers/cart";
import useAuth from "../../hooks/useAuth";
import Footer from "../Common/Footer";


function PostAuthLayout() {
  const [openMenu,setOpenMenu] = React.useState(false)

  function toggleOpenMenu(force){
    setOpenMenu((prev)=>typeof force !== 'undefined' ? force : !prev)
  }

  const auth = useAuth();
  if (!auth.token) return <Navigate to="/en/login" />;
  return (
    <SubmissionsProvider>
      <CartProvider>
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "250px",
            },
            height: "100vh",
            position: "fixed",
            zIndex: "500",
            top: {
              xs: openMenu ? 0 : -9999,
              md: 0,
            },
            left: 0,
            bgcolor: "white",
            p: "20px",
            tranisition: "top 0.2s ease-in-out",
          }}
        >
          <NavBar onNavLinkClick={()=>toggleOpenMenu(false)} />
        </Box>
        <Box sx={{
          textAlign: 'right',
          p: '7px',
          position: openMenu ? 'fixed' : 'relative',
          top:0,
          left: 0,
          width: '100%',
          zIndex: "600",
          display:{
            sx: 'block',
            md: 'none'
          }
        }} onClick={()=>toggleOpenMenu()}>
          {openMenu ?<CloseIcon sx={{
            width: '40px',
            height: '40px',
          }} /> : <MenuIcon sx={{
            width: '40px',
            height: '40px',
          }} /> }
          
          
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              md: "calc(100% - 250px)"
            },
            height: "100%",
            m: {
              xs: openMenu ? '0 0 0' : 0,
              // xs: '0',
              md: "0 0 0 250px",
            },
            padding: "20px",
          }}
        >
          <Outlet />
          <Box sx={{
            display:{
              xs: 'block',
              md: 'none'
            }
          }}>
            <Footer/>
          </Box>
        </Box>
      </CartProvider>
    </SubmissionsProvider>
  );
}

export default PostAuthLayout;
