import React from "react";
import { useNavigate } from "react-router-dom";

import TinyButton from "../../../Button/TinyButton";
import useCart from "../../../../hooks/useCart";
import useSubmissionData from "../../../../hooks/useSubmissionData";
import useLocale from "../../../../hooks/useLocale";
import { useTranslation } from "react-i18next";

function DraftButtons({ id }) {
  const navigate = useNavigate();
  const cart = useCart();
  const { locale } = useLocale();
  const submissionData = useSubmissionData();
  const { t } = useTranslation();

  const {
    getSubmissionFromId,
    insertSubmission,
    validateSubmission,
    deleteSubmission,
    updateSubmissions,
  } = submissionData;

  function goToEditSubmission(id) {
    navigate(`/${locale}/submission/${id}`);
  }

  function handleDelete(id) {
    deleteSubmission(id, (res) => {
      if (res)
        updateSubmissions();
    });
  }

  return (
    <>
      <TinyButton onClick={() => handleDelete(id)}>
        {t("yourSubmissions.body.card.button.delete")}
      </TinyButton>
      <TinyButton onClick={() => goToEditSubmission(id)}>
        {t("yourSubmissions.body.card.button.edit")}
      </TinyButton>
      {cart.hasItem(id) ? (
        <TinyButton onClick={() => cart.removeFromCart(id)}>
          {t("yourSubmissions.body.card.button.removeFromCart")}
        </TinyButton>
      ) : (
        <TinyButton
          onClick={() => {
            const validatedSubmission = validateSubmission(
              getSubmissionFromId(id)
            );
            insertSubmission(validatedSubmission);
            if (validatedSubmission.validated)
              cart.addToCart(validatedSubmission.uuid);
          }}
        >
          {t("yourSubmissions.body.card.button.addToCart")}
        </TinyButton>
      )}
    </>
  );
}

export default DraftButtons;
