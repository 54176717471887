import React from "react";
import { Link, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TwoColumnBody from "../components/PreAuth/TwoColumnsBody";
import ChangePasswordForm from "../components/ChangePassword/ChangePasswordForm";
import formUtils from "../utils/forms";
import { Trans, useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";

const ChangePasswordColumn1 = ({ token }) => (
  <Trans i18nKey={"changePassword.column1.body"}>
    <Typography variant="body1">
      To change your password, use the form.
    </Typography>
  </Trans>
);

function ChangePassword() {
  const [failure, setFailure] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const auth = useAuth();
  const params = useParams();
  const { t } = useTranslation();
  const { formData, setFormData, Form } = ChangePasswordForm();

  const { token } = params;

  return (
    <main>
      <TwoColumnBody
        title={t("changePassword.title")}
        column1={<ChangePasswordColumn1 token={token} />}
        column2={success ? "" : Form}
        success={success}
        failure={failure}
        button1={
          success
            ? []
            : [
                {
                  type: "primary",
                  label: t("changePassword.button1.submit"),
                  onClick: () => {
                    setFailure("");
                    setSuccess("");
                    const { password, repeatPassword } = formData;
                    //validate password
                    if (password.value.length === 0)
                      formUtils.updateFormError(
                        "password",
                        "Please provide a password",
                        setFormData
                      );
                    else if (password.value.length < 6)
                      formUtils.updateFormError(
                        "password",
                        "Your password must be at least 6 characters",
                        setFormData
                      );
                    else if (password.value !== repeatPassword.value) {
                      formUtils.updateFormError(
                        "password",
                        "Your passwords do not match",
                        setFormData
                      );
                    } else {
                      formUtils.updateFormError("password", "", setFormData);
                      auth.changePassword(
                        { token, password: password.value },
                        (res) => {
                          if (!res)
                            //TODO: add in email and move to dict
                            setFailure(
                              "We could not change your password at this time — please contact us at staff@magazineawards.com."
                            );
                          else {
                            setSuccess(
                              <Typography variant={"body2"}>
                                Your password has been reset. Login
                              </Typography>
                            );
                          }
                        }
                      );
                    }
                  },
                },
              ]
        }
        button2={[
          {
            label: (
              <Trans i18nKey={"changePassword.button2.signIn"}>
                Or, go back to <Link to="/login">login</Link>
              </Trans>
            ),
            position: "start",
          },
        ]}
      />
    </main>
  );
}

export default ChangePassword;
