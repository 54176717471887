import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RemoveIcon from "@mui/icons-material/Clear";

import SideBarLayout from "../components/PostAuth/SideBarLayout";
import NotFound from "../components/Submissions/NotFound";

import useCart from "../hooks/useCart";
import useSubmissionData from "../hooks/useSubmissionData";

import Callout from "../components/Callout/Callout";
import useLocale from "../hooks/useLocale";

function Cart() {
  const [disableSubmit] = React.useState(false);
  const [notFound] = React.useState(false);
  const [sideBar] = React.useState();
  const submissionData = useSubmissionData();
  const navigate = useNavigate();
  const cart = useCart();
  const { locale } = useLocale();

  const {
    submissions,

  } = submissionData;



  const buttons = [
    {
      type: "primary",
      label: "Go to payment",
      disabled: disableSubmit,
      onClick: () => {
        navigate(`/${locale}/payment`);
      },
    },
  ];

  if (notFound) return <NotFound />;
  return (
    <SideBarLayout
      title={"Checkout and submit"} //TODO: get from dict
      subtext={
        <>
          Review the entries you'd like to submit below and go to payment when
          you're ready. To add more submissions to your cart,{" "}
          <Link to={`/${locale}/your-submissions`}>
            go back to your submisssions
          </Link>
        </>
      } //TODO: get from dict
      buttons={buttons}
      sideBar={sideBar}
      body={
        <>
          <TableContainer sx={{ mb: "50px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Submission title</TableCell>
                  <TableCell>Publication</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submissions &&
                  submissions
                    .filter((submission) => cart.hasItem(submission.uuid))
                    .map((submission) => {
                      let priceData = cart.submissionPrice(submission);
                      return (
                        <TableRow key={submission.uuid}>
                          <TableCell>
                            <RemoveIcon
                              sx={{
                                cursor: "pointer",
                                height: "20px",
                                color: "#AAA",
                                pt: "5px",
                                ":hover": { color: "black" },
                              }}
                              onClick={() =>
                                cart.removeFromCart(submission.uuid)
                              }
                            />
                            {submission.title || "No title"}
                          </TableCell>
                          <TableCell>
                            {submission.publication || "No publication"}
                          </TableCell>
                          <TableCell>
                            {(submission.category &&
                              submission.category.title) ||
                              "No category"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "right" }}>
                            {cart.showDollarValue(priceData.price)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                <TableRow sx={{ borderTop: "2px solid #AAA" }}>
                  <TableCell colSpan={3}>
                    <Typography variant={"body2"}>Subtotal</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {" "}
                    <Typography variant={"body2"}>
                      {cart.showDollarValue(cart.payment.subtotal)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant={"body2"}>Tax</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    <Typography variant={"body2"}>
                      {cart.showDollarValue(cart.payment.tax)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant={"body1"}>Total</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {" "}
                    <Typography variant={"body1"}>
                      {cart.showDollarValue(cart.payment.total)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Callout elevation={0}>
            <Grid container spacing={2}>
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={cart.toggleFreelancer}
              >
                {cart.freelancer ? (
                  <RadioButtonCheckedIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Grid>
              <Grid item xs>
                <Typography onClick={cart.toggleFreelancer} variant={"body2"} gutterBottom>
                  <strong>Freelancer Support Fund</strong>
                </Typography>
                <Typography onClick={cart.toggleFreelancer} variant={"body2"}>
                  Yes, I qualify for the Freelancer Support Fund. Please verify
                  my standing and send me a revised invoice.
                </Typography>
                <Typography variant={"body2"}>
                  <button
                    style={{
                      // visibility: "hidden",
                      position: "absolute",
                      top: "-99999px",
                      left: "-99999px",
                      width: "1px",
                      height: "1px",
                      overflow: "hidden",
                    }}
                    onClick={cart.toggleFreelancer}
                  >
                    Click here to opt into the Freelancer Support Fund
                  </button>
                  <a
                    href={
                      locale === "fr"
                        ? "https://magazine-awards.com/fr/fonds-de-soutien-aux-pigistes/"
                        : "https://magazine-awards.com/en/support/freelancer-support-fund/"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    referrerPolicy="no-referrer"
                  >
                    Click here to see if you qualify.
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <hr style={{ margin: "30px 0" }} />
            <Grid container spacing={2}>
              <Grid
                item
                onClick={cart.toggleSmallMagazine}
                sx={{ cursor: "pointer" }}
              >
                {cart.smallMagazine ? (
                  <RadioButtonCheckedIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Grid>
              <Grid item xs>
                <Typography
                  onClick={cart.toggleSmallMagazine}
                  variant={"body2"}
                  gutterBottom
                >
                  <strong>Small Magazine Rebate</strong>
                </Typography>
                <Typography
                  onClick={cart.toggleSmallMagazine}
                  variant={"body2"}
                >
                  Yes, my magazine qualifies for the Small Magazine Rebate as
                  our total annual revenues are less than $200,000. Please
                  verify our standing and send me a revised invoice.
                </Typography>
                <Typography variant={"body2"}>
                  <button
                    style={{
                      // visibility: "hidden",
                      position: "absolute",
                      top: "-99999px",
                      left: "-99999px",
                      width: "1px",
                      height: "1px",
                      overflow: "hidden",
                    }}
                    onClick={cart.toggleSmallMagazine}
                  >
                    Click here to opt into the Small Magazine Rebate
                  </button>
                  <a
                    href={
                      locale === "fr"
                        ? "https://magazine-awards.com/fr/remise-pour-petites-publications/"
                        : "https://magazine-awards.com/en/support/small-magazine-rebate/"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    referrerPolicy="no-referrer"
                  >
                    Click here to see if you qualify.
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Callout>
        </>
      }
      success={null}
      failure={<p>Something went wrong</p>}
    />
  );
}

export default Cart;
