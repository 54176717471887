import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TwoColumnBody from "../components/PreAuth/TwoColumnsBody";
import ResetPasswordForm from "../components/ResetPassword/ResetPasswordForm";
import formUtils from "../utils/forms";
import useLocale from "../hooks/useLocale";
import useAuth from "../hooks/useAuth";
import { Trans, useTranslation } from "react-i18next";

const ResetPasswordColumn1 = () => (
  <Trans i18nKey={"resetPassword.column1.body"}>
    <Typography variant="body1">
      To reset your password, enter your email address and further instructions
      will be emailed.
    </Typography>
  </Trans>
);

function ResetPassword() {
  const [failure, setFailure] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const { t } = useTranslation();
  const auth = useAuth();
  const { locale } = useLocale();
  const { formData, setFormData, Form } = ResetPasswordForm({ t });

  return (
    <main>
      <TwoColumnBody
        title={t("resetPassword.title")}
        column1={<ResetPasswordColumn1 />}
        column2={success ? "" : Form}
        success={success}
        failure={failure}
        button1={
          success
            ? []
            : [
                {
                  type: "primary",
                  label: t("resetPassword.button1.submit"),
                  onClick: () => {
                    setFailure("");
                    setSuccess("");
                    const { email } = formData;
                    if (email.value.length === 0)
                      formUtils.updateFormError(
                        "email",
                        "Please provide an email",
                        setFormData
                      );
                    else if (
                      email.value.length > 0 &&
                      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email.value)
                    )
                      formUtils.updateFormError(
                        "email",
                        "Please enter a valid email",
                        setFormData
                      );
                    else {
                      formUtils.updateFormError("email", "", setFormData);
                      auth.resetPassword(
                        { email: email.value, lang: locale },
                        (res) => {
                          //TODO Move to dictionary
                          if (!res)
                            setFailure(
                              "Hmm... something went wrong. Try again, of if you have already created an account try logging in, or reseting your password"
                            );
                          else {
                            setSuccess(
                              <Typography variant={"body2"}>
                                Check your email to reset your password.
                              </Typography>
                            );
                          }
                        }
                      );
                    }
                  },
                },
              ]
        }
        button2={[
          {
            label: (
              <Trans i18nKey={"resetPassword.button2.signIn"}>
                Or, go back to <Link to="/login">login</Link>
              </Trans>
            ),
            position: "start",
          },
        ]}
      />
    </main>
  );
}

export default ResetPassword;
