import React from "react";

import TextField from "../Input/TextField";
import MediaDrop from "../Input/MediaDrop";
import useLocale from "../../hooks/useLocale";

function SubmissionDetailsForm(props) {
  const { submission, onChange, disabled } = props;
  const { fields } = submission;
  const { locale } = useLocale();

  function getFieldType(type) {
    switch (type) {
      case "title":
      case "publication":
      default:
        return "text";
    }
  }

  return (
    <form>
      {fields &&
        fields
          .sort((a, b) => (a.priority > b.priority ? 1 : -1))
          .map((field, i) => {
            let key = `details-field-${i}`;
            if (field.type === "upload")
              return (
                <MediaDrop
                  key={key}
                  {...field}
                  label={field[locale === "fr" ? "title_fr" : "title"]}
                  subtext={
                    field[locale === "fr" ? "placeholder_fr" : "placeholder"]
                  }
                  disabled={disabled}
                  disableFormSubmit={() => {}}
                  onChange={(e) => onChange(e)}
                />
              );

            return (
              <TextField
                {...field}
                key={field.id.toString()}
                required={field.required}
                error={field.error}
                id={field.id.toString()}
                name={field.id.toString()}
                value={field.value || ""}
                type={getFieldType(field.type)}
                label={field[locale === "fr" ? "title_fr" : "title"]}
                subtext={field.subtext}
                placeholder={
                  field[locale === "fr" ? "placeholder_fr" : "placeholder"]
                }
                disabled={disabled}
                onChange={(e) => onChange(e.target)}
                hideErrorLabel={field.hideErrorLabel}
              />
            );
          })}
    </form>
  );
}

export default SubmissionDetailsForm;
