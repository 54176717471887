import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLocale from "../../../../hooks/useLocale";

import TinyButton from "../../../Button/TinyButton";




function NonDraftButtons({ id }) {
  const navigate = useNavigate();
  const {locale} = useLocale();
  const {t} = useTranslation();


  function goToReviewSubmission(id) {
    navigate(`/${locale}/submission/review/${id}`);
  }

  return (
    <>
      <TinyButton onClick={() => goToReviewSubmission(id)}>{t('yourSubmissions.body.card.button.review')}</TinyButton>
    </>
  );
}

export default NonDraftButtons;
