import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TwoColumnBody from "../components/PreAuth/TwoColumnsBody";
import LoginForm from "../components/Login/LoginForm";
import validationUtils from "../utils/validation";
import useAuth from "../hooks/useAuth";
import useLocale from "../hooks/useLocale";
import { Trans, useTranslation } from "react-i18next";

const LoginColumn1 = () => {
  return (
    <>
      <Typography variant="body1">
        <Trans i18nKey="login.column1.intro">
          Welcome to the National Magazine Awards Submissions portal. To create
          your account, click on <strong>Register a new account</strong>.
        </Trans>
      </Typography>
      <Typography variant="body1">
        <Trans i18nKey="login.column1.body">
          If you have already created an account for this awards year, enter
          your username and password then <strong>Login</strong> to connect to
          the portal.
        </Trans>
      </Typography>
    </>
  );
};

const Callout = ({ t }) => (
  <>
    <Typography variant="body2">{t("login.callout")}</Typography>
  </>
);

function Login() {
  const [failure, setFailure] = React.useState("");
  const [success] = React.useState("");
  const auth = useAuth();
  const navigate = useNavigate();
  const { formData, setFormData, Form } = LoginForm();
  const { t } = useTranslation();
  const { locale } = useLocale();

  React.useEffect(() => {}, [formData]);

  function validateForm() {
    return (
      validationUtils.validateEmail(formData, setFormData) ||
      validationUtils.validatePassword(formData, setFormData)
    );
  }

  function submitLogin(locale) {
    setFailure("");

    const hasErrors = validateForm();

    if (!hasErrors) {
      const username = formData["email"].value;
      const password = formData["password"].value;
      auth.login({ username, password }, (status) => {
        if (status.error) setFailure(t("login.failure"));
        else {
          navigate(`/${locale}/your-submissions`);
        }
      });
    }
  }

  return (
    <main>
      <TwoColumnBody
        title={t('login.title')}
        column1={<LoginColumn1 />}
        column2={Form}
        callout={<Callout t={t} />}
        success={success}
        failure={failure}
        button1={[
          {
            type: "primary",
            label: t('login.button1.login'),
            disabled: !(
              formData.email.value &&
              formData.email.value.length > 0 &&
              formData.password.value &&
              formData.password.value.length >= 6
            ),
            onClick: () => submitLogin(locale),
          },
        ]}
        button2={[
          {
            label: (
              <Link to={`/${locale}/register`}>
                {t("login.button2.register")}
              </Link>
            ),
            position: "start",
          },
          {
            label: (
              <Link to={`/${locale}/reset-password`}>
                {t("login.button2.forgotPassword")}
              </Link>
            ),
            position: "end",
          },
        ]}
      />
    </main>
  );
}

export default Login;
