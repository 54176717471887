import { Grid } from "@mui/material";
import React from "react";
import PaymentButton from "../Payment/PaymentButton";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

function ButtonGroup({ buttons, top = false }) {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        justifyContent: "flex-end",
      }}
    >
      {buttons.map((button, i) => {
        const { id, onClick, disabled, label, type, hideTop } = button;
        if(top && hideTop) return (<></>)
        if (type === "paypal")
          return (
            <Grid item xs={2}>
              <PaymentButton callback={onClick} payment={button.cart} />
            </Grid>
          );
        if (type === "primary")
          return (
            <Grid item>
              <PrimaryButton
                key={[id, "-", i].join()}
                onClick={onClick}
                disabled={disabled}
              >
                {label}
              </PrimaryButton>
            </Grid>
          );
        return (
          <Grid item>
            <SecondaryButton
              key={[id, "-", i].join()}
              onClick={onClick}
              disabled={disabled}
            >
              {label}
            </SecondaryButton>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ButtonGroup;
