import React from "react";
import { Link } from "react-router-dom";
import TwoColumnBody from "../components/PreAuth/TwoColumnsBody";
import RegisterForm from "../components/Register/RegisterForm";
import Typography from "@mui/material/Typography";
import formUtils from "../utils/forms";
// import validationUtils from "../utils/validation";
import useAuth from "../hooks/useAuth";
import { Trans, useTranslation } from "react-i18next";
import useLocale from "../hooks/useLocale";

const RegisterColumn1 = ({ Trans, t = () => {} }) => (
  <>
    <Typography variant="body1">{t("register.column1.intro")}</Typography>
    <Typography variant="body1">
      <Trans i18nKey={"register.column1.body"}>
        If you have already created an account for this awards year, click{" "}
        <strong>Sign in</strong> below.
      </Trans>
    </Typography>
  </>
);

const Callout = ({ t }) => (
  <>
    <Typography variant="body2">{t("register.callout")}</Typography>
  </>
);

function Register() {
  const [failure, setFailure] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const {locale} = useLocale()

  const { t } = useTranslation();
  const auth = useAuth();

  const { formData, setFormData, Form } = RegisterForm({ Trans, t });

  function validateForm() {
    const { fullName, email, password, terms } = formData;
    let hasError = false;

    if (fullName.value.length === 0) {
      hasError = true;
      formUtils.updateFormError(
        "fullName",
        "Please provide a full name",
        setFormData
      );
    } else if (fullName.value.length === 1) {
      hasError = true;
      formUtils.updateFormError(
        "fullName",
        "Your name must be longer than 1 character",
        setFormData
      );
    } else formUtils.clearFormError("fullName", setFormData);
    if (email.value.length === 0) {
      hasError = true;
      formUtils.updateFormError(
        "email",
        "Please provide an email",
        setFormData
      );
    } else if (
      email.value.length > 0 &&
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email.value)
    ) {
      hasError = true;
      formUtils.updateFormError(
        "email",
        "Please enter a valid email",
        setFormData
      );
    } else formUtils.updateFormError("email", "", setFormData);

    //validate password
    // NB this already exists in /utils/validation.js
    if (password.value.length === 0) {
      hasError = true;
      formUtils.updateFormError(
        "password",
        "Please provide a password",
        setFormData
      );
    } else if (password.value.length < 6) {
      hasError = true;
      formUtils.updateFormError(
        "password",
        "Your password must be at least 6 characters",
        setFormData
      );
    } else formUtils.updateFormError("password", "", setFormData);

    if (terms && !terms.value) {
      hasError = true;
      formUtils.updateFormError(
        "terms",
        "You must agree to the terms below",
        setFormData
      );
    } else formUtils.updateFormError("terms", "", setFormData);
    // return (
    //   validationUtils.validateEmail(formData, setFormData) ||
    //   validationUtils.validatePassword(formData, setFormData)
    // );

    return hasError;
  }

  function submitRegistration() {
    setFailure("");
    setSuccess("");

    const hasErrors = validateForm();

    if (!hasErrors) {
      let data = {};
      Object.keys(formData).forEach((v) => (data[v] = formData[v].value));
      data.lang = locale
      auth.register(data, (submitter) => {
        //TODO Move to dictionary
        if (!submitter)
          setFailure(
            "Hmm... something went wrong. Try again, of if you have already created an account try logging in, or reseting your password"
          );
        else {
          setSuccess(
            <Typography variant={"body2"}>
              You have successfully registered your account. <Link to={`/${locale}/login`}>Log in</Link>
            </Typography>
          );
        }
      });
    }
  }

  return (
    <main>
      <TwoColumnBody
        title={t("register.title")}
        column1={<RegisterColumn1 Trans={Trans} t={t} />}
        column2={!success && Form}
        callout={<Callout t={t} />}
        success={success}
        failure={failure}
        button1={
          !success
            ? [
                {
                  type: "primary",
                  label: t("register.button1.register"),
                  onClick: () => submitRegistration(),
                },
              ]
            : []
        }
        button2={
          !success
            ? [
                {
                  label: (
                    <Trans i18nKey={"register.button2.signIn"}>
                      Have an account? <Link to={`/${locale}/login`}>Sign in</Link>
                    </Trans>
                  ),
                  position: "start",
                },
              ]
            : []
        }
      />
    </main>
  );
}

export default Register;
