import React, { useState, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

function SelectInput({
  label,
  onFocus,
  onBlur,
  onChange,
  name,
  data,
  value,
  sortOptions = false,
  optionsCallback,
  lang,
  disabled,
  error,
}) {
  const [locale, setLocale] = useState(lang);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([
    {
      value: lang === "fr" ? "Téléchargement" : "Loading",
      label:
        lang === "fr" ? "Télécharger les catégories" : "Loading categories...",
    },
  ]);

  if (locale !== lang) {
    setLocale(lang);
  }

  useEffect(() => {
    const newOptions = data
      .map((category) => {
        return {
          value: category.id,
          label: category["title" + (locale === "fr" ? "_fr" : "")],
        };
      })
      .sort((a, b) => sortOptions && (a.label[0] < b.label[0] ? -1 : 1));
    setOptions(newOptions);
    // optionsCallback(setOptions)
    setLoading(false);
  }, [locale, data]);

  // useEffect(() => {}, []);

  return (
    <FormControl fullWidth>
      {error && <Typography variant={"error"}>error</Typography>}
      <FormLabel id={`label-${name}`}>{label}</FormLabel>
      <Select
        aris-labelledby={`label-${name}`}
        isDisabled={disabled}
        lang={lang}
        value={value && options.filter((o)=>o.value.toString()===value.toString())}
        options={options}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(r) => onChange(r)}
        isLoading={loading}
        isSearchable={true}
        name={name}
      />
    </FormControl>
  );
}

SelectInput.displayName = "SelectInput";
SelectInput.propTypes = {
  lang: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  optionsCallback: PropTypes.func,
};

export default SelectInput;
