import React from "react";
import { submissionsService } from "../services/submissions";
import SubmissionsContext from "../context/submissions";

function SubmissionsProvider({ children }) {
  let [submissions, setSubmissions] = React.useState(null);
  let [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    updateSubmissions();
  }, []);

  let updateSubmissions = (callback) => {
    fetchSubmissions((res) => {
      setSubmissions(res);
      callback && callback(res);
    });
  };

  let fetchSubmissions = (callback) => {
    submissionsService.getUserSubmissions((res) => {
      setLoading(false)
      callback(res);
    });
    return;
  };

  let saveSubmission = (submission, callback) => {
    submissionsService.saveSubmission(submission, (res) => {
      callback(res);
    });
  };  

  const deleteSubmission = (id,callback) => {
    submissionsService.deleteUserSubmissions(id,callback)
  }

  let getSubmissionFromId = (submissionId) => {

    if (submissions && submissions.length) {
      const findSubmission = submissions.find(
        (sub) => sub.uuid === submissionId
      );
      return findSubmission;
    }
  };

  let insertSubmission = (submission) => {
    try {
      const { id } = submission;
      setSubmissions((prev) => {
        let copy_submissions = [...prev];
        const updated = [
          ...copy_submissions.splice(
            0,
            copy_submissions.findIndex((s) => s.id.toString() === id.toString())
          ),
          {
            ...submission,
          },
          ...copy_submissions.splice(1, copy_submissions.length - 1),
        ];

        return updated;
      });
    } catch (e) {
      return false;
    }
  };

  let validateSubmission = (submission) => {
    //Entry requires language, organization and category to be created — not checked during validation
    let errorCount = 0;
    let { fields } = submission;
    submission &&
      fields &&
      fields.forEach((field) => {
       
        if (
          field.required &&
          (!field.value || field.value.trim().length === 0)
        ) {
          errorCount++;
          fields = insertValueIntoFieldSetAtId(
            fields,
            {
              error:
                field.type === "upload"
                  ? "Please upload a file"
                  : "Please enter a value",
            },
            field.id
          );
        } else {
          fields = insertValueIntoFieldSetAtId(
            fields,
            { error: null },
            field.id
          );
        }
        // }
      });
    if (errorCount === 0) {
      //Send to API to validate
    }

    return { ...submission, fields, errorCount, validated: errorCount === 0 };
  };

  const submitPayment = (
    { cart, paypalData = {}, lang, cheque = false },
    callback
  ) => {
    try {
      const { freelancer, smallMagazine } = cart;
      const { amount, paymentId, paymentTime, orderId } = paypalData;
      return submissionsService.submitPayment(
        {
          uuids: cart.cart,
          freelancer,
          smallMagazine,
          amount,
          paymentId,
          paymentTime,
          orderId,
          lang,
          cheque,
        },
        callback
      );
    } catch (e) {
      return callback(false);
    }
  };

  function insertValueIntoFieldSetAtId(fieldSet, value, id) {

    try {
      let copy_fieldSet = [...fieldSet];
      return [
        ...copy_fieldSet.splice(
          0,
          copy_fieldSet.findIndex((f) => f.id.toString() === id.toString())
        ),
        {
          ...copy_fieldSet[0],
          ...value,
        },
        ...copy_fieldSet.splice(1, copy_fieldSet.length - 1),
      ];
    } catch (e) {
      return fieldSet;
    }
  }

  const validatedSubmissionCondition = (submission) =>
    submission && submission.validated;

  const submittedSubmissionCondition = (submission) =>
    submission && submission.submitted;

  const paidSubmissionCondition = (submission) => submission && submission.paid;

  const draftSubmissionCondition = (submission) =>
    submission &&
    submission.payment === "unpaid" &&
    (submission.specialConsideration === "draft" ||
      submission.specialConsideration === "ready");

  //TODO: Unpaid state does not exists. API update needed
  const unpaidSubmissionCondition = (submission) =>
    submission &&
    submission.payment === "paid" &&
    submission.specialConsideration === "unpaid";

  const nmaCondition = (submission) =>
    submission && submission.organization.shortName.toLowerCase() === "nma";
  const dpaCondition = (submission) =>
    submission && submission.organization.shortName.toLowerCase() === "dpa";
  const b2bCondition = (submission) =>
    submission && submission.organization.shortName.toLowerCase() === "b2b";

  let value = {
    submissions,
    updateSubmissions,
    fetchSubmissions,
    saveSubmission,
    deleteSubmission,
    insertSubmission,
    validateSubmission,
    getSubmissionFromId,
    insertValueIntoFieldSetAtId,
    validatedSubmissionCondition,
    submittedSubmissionCondition,
    draftSubmissionCondition,
    unpaidSubmissionCondition,
    paidSubmissionCondition,
    nmaCondition,
    dpaCondition,
    b2bCondition,
    submitPayment,
    loading,
  };

  return (
    <SubmissionsContext.Provider value={value}>
      {children}
    </SubmissionsContext.Provider>
  );
}

export default SubmissionsProvider;
