import React from "react";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";

import PrimaryButton from "../../Button/PrimaryButton";

// import styles from "../../../styles/PreAuth/TwoColumnBody/TitleBar";

function Button1(props) {
  const { buttons } = props;
  return (
    <Box sx={{ textAlign: "right", padding: "5px 10px 35px" }}>
      <ButtonGroup sx={{ textAlign: "center" }}>
        {buttons.map((button) => {
          const { label, onClick, disabled, id} = button;
          switch (button.type) {
            case "secondary":
              return <></>;

            case "primary":
            default:
              return (
                <PrimaryButton disabled={disabled} onClick={onClick} key={id}>
                  {label}
                </PrimaryButton>
              );
          }
        })}
      </ButtonGroup>
    </Box>
  );
}

export default Button1;
