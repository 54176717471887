import React from "react";

import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Table from "./Table";
import Swirl from "../../Common/Swirl";
import Error from "../../Callout/Error"

// import useSubmissionData from "../../../hooks/useSubmissionData";
import DraftButtons from "./Buttons/DraftButtons";
import NonDraftButtons from "./Buttons/NonDraftButtons";
import { useTranslation } from "react-i18next";


function SubmissionCard(props) {
  // const submissionData = useSubmissionData();
  const {t} = useTranslation();

  const {
    id,
    title,
    category,
    publication,
    org,
    organization,
    createdAt,
    updatedAt,
    errorCount,
    submitted,
  } = props;

 

  return (
    <Card>
      {/* TODO: Replace org with org */}
      <CardHeader
        avatar={
          <Avatar aria-label={organization && organization.title} sx={{ bgcolor: "white" }}>
            <Swirl org={organization && (organization.shortName || "nma")} />
          </Avatar>
        }
        title={
          <Typography variant={"label"} sx={{ fontWeight: 500 }}>
            {title || t('yourSubmissions.body.card.defaultTitle')}
          </Typography>
        }
      />
      <CardContent>
        <Grid container columns={2} spacing={4}>
          <Grid item xs={1}>
            <Table
              label={t('yourSubmissions.body.card.infoTableLabel')}
              variant={"info"}
              category={category}
              publication={publication}
              org={org}
              organization={organization}
            />
          </Grid>

          <Grid item xs={1}>
            <Table
              label={t('yourSubmissions.body.card.timeTableLabel')}
              variant={"time"}
              createdAt={createdAt}
              updatedAt={updatedAt}
            />
          </Grid>
        </Grid>
        { errorCount > 0 &&<Error elevation={0}>{t('yourSubmissions.body.card.addToCartError')}</Error>}
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        {!submitted ? <DraftButtons id={id}/> : <NonDraftButtons id={id} />}
      </CardActions>
    </Card>
  );
}

export default SubmissionCard;
