import React from "react";
import DefaultTable from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";

import TableHead from "./TableHead";
import TableBody from "./TableBody";

function Table(props) {
  const {
    variant,
    title,
    category,
    publication,
    org,
    organization,
    createdAt,
    updatedAt,
  } = props;

  return (
    <TableContainer>
      <DefaultTable aria-label={"Submission Info Table"}>
        <TableHead />
        <TableBody
          variant={variant}
          title={title}
          category={category}
          publication={publication}
          org={org}
          organization={organization}
          createdAt={createdAt}
          updatedAt={updatedAt}
        />
      </DefaultTable>
    </TableContainer>
  );
}

export default Table;
