import React from "react";
import Callout from "../Callout/Callout";


function NotFound() {
  return (
    <Callout>
      We could not find the submissions you're looking for. Please go back and
      try again.
    </Callout>
  );
}


export default NotFound