import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Success from "../Callout/Success";
import useLocale from "../../hooks/useLocale";
import Callout from "../Callout/Callout";
import { Grid } from "@mui/material";

function PaymentSuccess() {
  const { locale } = useLocale();
  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12} sm={12}>
        <Success elevation={0}>
          <Typography variant={"body2"}>
            Your payment information has been received. Thank you!
          </Typography>
          <Typography variant={"body2"}>
            <Link to={`/${locale}/your-submissions`}>
              Go back to your submissions
            </Link>
          </Typography>
        </Success>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Callout customStyles={{marginTop:0}} elevation={0}>
          <Typography variant={"h2"}>
            Paying by e-Transfer or cheque?
          </Typography>
          <Typography variant={"body2"} gutterBottom>
            We request payment by either PayPal or Interac e-Transfer to
            staff@magazine-awards.com. <strong>Your payment must include your invoice
            number.</strong>
          </Typography>
          <Typography variant={"body2"} gutterBottom>
            If paying by cheque is your only option, attach or staple the cheque
            to a copy of your invoice and notify us to expect a cheque.
          </Typography>
          <Typography variant={"body2"} gutterBottom>
            We cannot be responsible for matching e-transfers or cheques that
            arrive without an invoice number. Entries with payments not received
            by February 15, 2025 can NOT be sent for judging.
          </Typography>

        </Callout>
      </Grid>
    </Grid>
  );
}

export default PaymentSuccess;
