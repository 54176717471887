import React from "react";
import Button from "@mui/material/Button";
import styles from '../../styles/Button/SecondaryButton'


function SecondaryButton(props){
    const {children, onClick, disabled} = props
    return(
        <Button disabled={disabled} onClick={onClick} sx={styles}>{children}</Button>
    )
}

export default SecondaryButton