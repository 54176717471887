import React from "react";
import NMASwirl from "../../static/images/swirl_nma.png"
import DPASwirl from "../../static/images/swirl_dpa.png"
import B2BSwirl from "../../static/images/swirl_b2b.png"

function Swirl({org}){
    function getSwirlForOrg(org){
        switch(org && org.toLowerCase()){
            case 'dpa': return DPASwirl;
            case 'b2b': return B2BSwirl;
            case 'nma':
            default:
                return NMASwirl;
        }
    }
    return (
        <img style={{
            width: '90%'
        }} src={getSwirlForOrg(org)} alt="" />
    )
}

export default Swirl