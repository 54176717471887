const SubmissionInfoSideBarRows = ({
  program,
  category,
  language,
  entryId,
  updatedAt,
  t = ()=>{},
}) => [
  { label: t('submission.details.sidebar.program'), value: program || "" },
  { label: t('submission.details.sidebar.category'), value: category || "" },
  { label: t('submission.details.sidebar.language'), value: language || "" },
  { label: t('submission.details.sidebar.entryId'), value: entryId || "" },
  { label: t('submission.details.sidebar.lastSaved'), value: updatedAt || "" },
];

export default (props) => {
  const {t} = props;
  return {
    variant: "list",
    title: t('submission.details.sidebar.title'),
    rows: SubmissionInfoSideBarRows(props || {}),
  };
};
