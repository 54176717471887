

import React from "react";
import dayjs from "dayjs";
import 'dayjs/locale/fr'

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import DefaultTableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import useLocale from "../../../../hooks/useLocale";

function printTime(time, t = ()=>{}, locale = 'en') {
  const day = dayjs(time).locale(locale);
  if (dayjs().isSame(day, "day")) return day.format(`[${t('time.sameDay.label')}] ${t('time.sameDay.format')}`);
  return day.format(t('time.format'));
} 


function TableBody(props) {
  const {
    variant,
    category,
    publication,
    organization,
    createdAt,
    updatedAt,
  } = props;
  const {t} = useTranslation();
  const {locale} = useLocale();

  const SubmissionTableCell = styled(TableCell)((global) => {
    return {
      display: "block",
      border: 0,
      padding: "0",

      "td&": {
        marginBottom: "10px",
      },
    };
  });

  if (variant === "time")
    return (
      <DefaultTableBody>
        <TableRow sx={{ display: "block" }}>
          <SubmissionTableCell component={"th"} scope={"row"}>
            <Typography variant={"smallLabel"}>{t('yourSubmissions.body.card.table.dateLastSaved')}</Typography>
          </SubmissionTableCell>
          <SubmissionTableCell>
            <Typography variant={"body3"}>
              {updatedAt ? printTime(updatedAt,t,locale) : <>&ndash;</>}
            </Typography>
          </SubmissionTableCell>
        </TableRow>
        <TableRow sx={{ display: "block" }}>
          <SubmissionTableCell component={"th"} scope={"row"}>
            <Typography variant={"smallLabel"}>{t('yourSubmissions.body.card.table.dateCreated')}</Typography>
          </SubmissionTableCell>
          <SubmissionTableCell>
            <Typography variant={"body3"}>
              {createdAt ? printTime(createdAt,t,locale) : <>&ndash;</>}
            </Typography>
          </SubmissionTableCell>
        </TableRow>
      </DefaultTableBody>
    );
  else //i.e. if variant === "info"
    return (
      <DefaultTableBody>
        <TableRow sx={{ display: "block" }}>
          <SubmissionTableCell component={"th"} scope={"row"}>
            <Typography variant={"smallLabel"}>{t('yourSubmissions.body.card.table.category')}</Typography>
          </SubmissionTableCell>
          <SubmissionTableCell>
            <Typography variant={"body3"}>
              {category || t('yourSubmissions.body.card.table.defaultCategory')}
            </Typography>
          </SubmissionTableCell>
        </TableRow>
        <TableRow sx={{ display: "block" }}>
          <SubmissionTableCell component={"th"} scope={"row"}>
            <Typography variant={"smallLabel"}>{t('yourSubmissions.body.card.table.publication')}</Typography>
          </SubmissionTableCell>
          <SubmissionTableCell>
            <Typography variant={"body3"}>
              {" "}
              {publication || t('yourSubmissions.body.card.table.defaultPublication')}
            </Typography>
          </SubmissionTableCell>
        </TableRow>
        <TableRow sx={{ display: "block" }}>
          <SubmissionTableCell component={"th"} scope={"row"}>
            <Typography variant={"smallLabel"}>{t('yourSubmissions.body.card.table.organization')}</Typography>
          </SubmissionTableCell>
          <SubmissionTableCell>
            <Typography variant={"body3"}>
              {organization && (organization.title || t('yourSubmissions.body.card.table.defaultOrganization'))}
            </Typography>
          </SubmissionTableCell>
        </TableRow>
      </DefaultTableBody>
    );
}

export default TableBody;
