import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";

import TitleBar from "./TitleBar";
import Button1 from "./Button1";
import Button2 from "./Button2";

import Callout from "../../Callout/Callout";
import Success from "../../Callout/Success";
import Error from "../../Callout/Error";

import styles from "../../../styles/PreAuth/TwoColumnBody";

function checkColumn1(props) {
  const { column1, callout } = props;
  return column1 || callout;
}

function checkColumn2(props) {
  const { column2, success, failure, button1, button2 } = props;
  return (
    column2 ||
    success ||
    failure ||
    (button1 && button1.length > 0) ||
    (button2 && button2.length > 0)
  );
}

function getColumnWidth(props) {
  if (checkColumn1(props) && checkColumn2(props)) return 6;
  return 12;
}

function TwoColumnBody(props) {
  const {
    title,
    column1,
    column2,
    callout,
    success,
    failure,
    button1,
    button2,
  } = props;
  const columnWidth = getColumnWidth(props);

  return (
    <Container>
      <Paper sx={styles} elevation={4}>
        {title && title.length > 0 && <TitleBar title={title} />}
        <Grid container spacing={3} direction={{xs:'column-reverse', md:'row'}}>
          {checkColumn1(props) && (
            <Grid item xs={12} md={columnWidth}>
              {column1}
              {callout && <Callout>{callout}</Callout>}
            </Grid>
          )}
          {checkColumn2(props) && (
            <Grid item xs={12} md={columnWidth}>
              <form onSubmit={(e)=>e.preventDefault()}>
                {success && <Success>{success}</Success>}
                {column2 && column2}
                {failure && <Error>{failure}</Error>}
                {button1 && button1.length > 0 && <Button1 buttons={button1} />}
                {button2 && button2.length > 0 && <Button2 buttons={button2} />}
              </form>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}

export default TwoColumnBody;


