import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonGroup from "../../Button/ButtonGroup";

const MainBodyHeader = ({ title, subtext, buttons }) => (
  <Grid
    container
    sx={{
      padding: "10px 0 35px",
    }}
  >
    <Grid item lg={6} md={6} xs={12}>
      <Typography variant={"h1"}>{title}</Typography>
      <Typography variant={"body1"}>{subtext}</Typography>
    </Grid>
    {buttons && buttons.length > 0 && (
      <Grid sx={{textAlign:'right'}} item lg={6} md={6} xs={12}>
        <ButtonGroup top buttons={buttons} />
      </Grid>
    )}
  </Grid>
);

export default MainBodyHeader;
