import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function Table({ rows }) {
  const Row = ({ label, value, showBorder = true }) => {
    const RowStyles = {
      borderBottom: showBorder ? '1px solid rgba(0,0,0,0.05)' : 0,
      padding: '10px 0'
    };

    return (
      <Grid container columns={4} direction="row" sx={RowStyles}>
        <Grid item xs>
          <Typography variant={'label'}>{label}</Typography>
        </Grid>
        <Grid item xs={1} sx={{textAlign:'right'}}>
        <Typography variant={'body2'}>{value}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    rows &&
    rows.length > 0 &&
    rows.map((row, i) => {
      const { label, value } = row;
      return <Row key={`row-${i}`} label={label} value={value} showBorder={i<rows.length-1}/>;
    })
  );
}

export default Table;
