import React from "react";
import { Link, useNavigate } from "react-router-dom";

import SideBarLayout from "../components/PostAuth/SideBarLayout";
import YourSubmissionsSideBar from "../components/YourSubmissions/SideBar";
import Section from "../components/YourSubmissions/Section";

import useSubmissionData from "../hooks/useSubmissionData";

import useCart from "../hooks/useCart";
import useLocale from "../hooks/useLocale";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

function YourSubmissions() {
  const { t } = useTranslation();

  const [buttons, setButtons] = React.useState([
    {
      type: "primary",
      label: t("yourSubmissions.buttons.create"),
      onClick: goToCreateSubmission,
      createSubmission: true,
      labelName: "yourSubmissions.buttons.create",
    },
  ]);
  const [filter, setFilter] = React.useState({
    search: null,
    count: {
      draft: 0,
      paid: 0,
      nma: 0,
      dpa: 0,
      b2b: 0,
    },
  });

  const navigate = useNavigate();
  const cart = useCart();
  const submissionData = useSubmissionData();
  const { locale } = useLocale();

  const {
    submissions,
    submittedSubmissionCondition,
    draftSubmissionCondition,
    paidSubmissionCondition,
    nmaCondition,
    dpaCondition,
    b2bCondition,
    loading,
  } = submissionData;

  React.useEffect(() => {
    if (submissionData && submissionData.submissions) {
      setFilter(() => ({
        count: {
          draft: submissions.filter(draftSubmissionCondition).length,
          paid: submissions.filter(paidSubmissionCondition).length,
          nma: submissions.filter(nmaCondition).length,
          dpa: submissions.filter(dpaCondition).length,
          b2b: submissions.filter(b2bCondition).length,
        },
      }));
    }
  }, [submissionData]);

  React.useEffect(() => {
    if (cart.hasItems())
      setButtons((prev) => [
        ...prev.filter((a) => !a.addToCart),
        {
          type: "primary",
          label: t("yourSubmissions.buttons.cart"),
          labelName: "yourSubmissions.buttons.cart",
          onClick: goToCart,
          addToCart: true,
          disabled: !cart.hasItems(), //TODO: this does not work
        },
      ]);
   
  }, [cart]);

  const LoadingMessage = React.useCallback(()=>{
    if(loading) return <Typography variant={'body1'}>Loading...</Typography>
    else return <Typography variant={'body1'}>Get started by <Link to={`/${locale}/submission/new`}>creating a submission</Link></Typography>
  },[locale, loading])

  React.useEffect(() => {
    setButtons((prev) => prev.map((button) => ({
      ...button,
      label: t(button.labelName),
    })));
  }, [t]);

  function goToCreateSubmission() {
    navigate(`/${locale}/submission/new`);
  }

  function goToCart() {
    navigate(`/${locale}/cart`);
  }
  return (
    <SideBarLayout
      title={t("yourSubmissions.title")}
      subtext={t("yourSubmissions.subtext")}
      buttons={buttons}
      sideBar={YourSubmissionsSideBar({
        submissions: {
          cart: cart.cart.length,
          draft: Math.max(filter.count.draft - cart.cart.length, 0),
          paid: filter.count.paid,
          nma: filter.count.nma,
          dpa: filter.count.dpa,
          b2b: filter.count.b2b,
        },
      })}
      body={
        //TODO: Move to dictionary
        <>
          {submissions && submissions.length > 0 ? (
            <>
              {cart.hasItems() && (
                <Section
                  title={t("yourSubmissions.body.cartSubmissions.title")}
                  subtext={t("yourSubmissions.body.cartSubmissions.subtext")}
                  submissions={submissions.filter(
                    (submission) =>
                      !submittedSubmissionCondition(submission) &&
                      cart.hasItem(submission.uuid)
                  )}
                />
              )}

              <Section
                title={t("yourSubmissions.body.draftSubmissions.title")}
                subtext={t("yourSubmissions.body.draftSubmissions.subtext")}
                submissions={submissions.filter(
                  (submission) =>
                    !submittedSubmissionCondition(submission) &&
                    !cart.hasItem(submission.uuid)
                )}
              />
              <Section
                title={t("yourSubmissions.body.completedSubmissions.title")}
                subtext={t("yourSubmissions.body.completedSubmissions.subtext")}
                submissions={submissions.filter(submittedSubmissionCondition)}
              />
            </>
          ) : <LoadingMessage/>}
        </>
      }
      success={<></>}
      failure={<></>}
    />
  );
}

export default YourSubmissions;
