import React from "react";
import Grid from "@mui/material/Grid";
import ButtonGroup from "../../Button/ButtonGroup";

const MainBodyFooter = ({ buttons }) => (
  <Grid container sx={{mt:'20px'}}>
    {buttons && buttons.length > 0 && (
      <Grid item xs>
        <ButtonGroup buttons={buttons} />
      </Grid>
    )}
  </Grid>
);

export default MainBodyFooter;
