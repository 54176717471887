import React from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

function RadioInput({ label, data, name, value, onChange, error, disabled }) {
  const [options, setOptions] = React.useState([
    {
      value: "loading",
      label: "Loading options..",
    },
  ]);

  const [sel] = React.useState(value);

  const handleClick = (e) => {
    let { target } = e;
    // setSel(e.target.value);
    onChange({ id: name, value: target.value });
  };

  React.useEffect(() => {
    if (data) setOptions(data);
  }, [data]);

  return (
    <FormControl fullWidth>
      {error && <Typography variant={"error"}>error</Typography>}
      <FormLabel id={`label-${name}`}>{label} </FormLabel>
      <RadioGroup
        disabled={disabled || false}
        row
        name={name}
        value={sel}
        onChange={handleClick}
        aria-labelledby={`label-${name}`}
        sx={{mt:'-10px'}}
      >
        {options.map((el, i) => (
          <FormControlLabel
            sx={{ ".MuiTypography-body1": { marginBottom: 0 } }}
            value={el.value}
            control={<Radio />}
            label={el.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioInput.displayName = "RadioInput";
RadioInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultSelected: PropTypes.string,
  data: PropTypes.object,
  callback: PropTypes.func,
  error: PropTypes.string,
};

export default RadioInput;
