import formUtils from "./forms";

function validateEmail(field, setter) {
  const value = field.email.value;
  const required = field.email.required;
  let hasError = false;
  if (required && (!value || value.length === 0)) {
    hasError = true;
    formUtils.updateFormError(
      "email",
      "Please provide an email address",
      setter
    );
  } else if (
    value.length > 0 &&
    !/^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/.test(value)
  ) {
    hasError = true;
    formUtils.updateFormError("email", "Please enter a valid email", setter);
  } else {
    formUtils.updateFormError("email", "", setter);
  }

  return hasError;
}

function validatePassword(field,setter){
    const value = field.password.value;
    let hasError = false;
    if (value.length === 0) {
        hasError = true;
        formUtils.updateFormError(
          "password",
          "Please provide a password",
          setter
        );
      } else if (value.length < 6) {
        hasError = true;
        formUtils.updateFormError(
          "password",
          "Your password must be at least 6 characters",
          setter
        );
      } else formUtils.updateFormError("password", "", setter);
      return hasError
}

export default {
    validateEmail,
    validatePassword,
}
