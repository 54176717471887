import React from "react";
import InitI18n, { languages } from "../i18n";
import LocaleContext from "../context/locale";
import { useTranslation } from "react-i18next";

InitI18n();

function LocaleProvider({ children }) {
  let [locale, setLocale] = React.useState(languages.en);
  let { i18n, language } = useTranslation();

  React.useEffect(() => {
    if (locale !== language) i18n.changeLanguage(locale);
  }, [locale]);

  let toggleLang = (force = undefined) => {
    if (typeof force === "string") {
      if (locale !== force) setLocale(force);
    } else setLocale(locale === languages.en ? languages.fr : languages.en);
  };

  let value = { locale, toggleLang };

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}

export default LocaleProvider;
