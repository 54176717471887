import React from "react";
import TextField from "../Input/TextField";
import formUtils from "../../utils/forms";
import { useTranslation } from "react-i18next";

function ResetPasswordForm() {
  const {t} = useTranslation();
  const [formData, setFormData] = React.useState({
    email: {
      id: "email",
      name: "email",
      onChange: (e) =>
        formUtils.updateFormValue("email", e.target.value, setFormData),
      value: "",
      type: "text",
      label: t('resetPassword.form.email.label'),
      placeholder: t('resetPassword.form.email.placeholder'),
      error: "",
      required: true,
    },
  });

  React.useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      email: {
        ...prev.email,
        label: t("login.form.email.label"),
        placeholder: t("login.form.email.placeholder"),
      },
    }));
  }, [t]);

  React.useEffect(() => {}, [formData]);

  return {
    formData,
    setFormData,
    Form: (
      <>
        <TextField {...formData.email} />
      </>
    ),
  };
}

export default ResetPasswordForm;
