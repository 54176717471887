import React from "react";
import FormControl from "@mui/material/FormControl";
// import OutlinedInput from "@mui/material/OutlinedInput";
import DefaultCheckbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function TextField(props) {
  const {
    id,
    name,
    onChange,
    type,
    label,
    error,
    disabled,
    required,
    checked,
  } = props;
  return (
    <FormControl fullWidth={true} variant={"outlined"} required={required}>
      {error && <Typography variant={"error"}>{error}</Typography>}
      <Grid container>
        <Grid item xs={1}>
          <DefaultCheckbox
            // error={error}
            type={type}
            id={id}
            name={name || id}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            sx={
              // TODO: Replace Colours with Global
              { color: error ? "#BA1A1A" : "black" }
            }
          />
        </Grid>
        <Grid item xs={11}>
          <label htmlFor={id}>
            <Typography variant={"label"}>{label}</Typography>
          </label>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default TextField;
