

function updateFormValue(name,value,setter){
    setter((prev)=>({
        ...prev,
        [name]: {
          ...prev[name],
          value: value,
        },
      }));
      return null;
}

function updateFormError(name,error,setter){
  setter((prev)=>({
      ...prev,
      [name]: {
        ...prev[name],
        error: error,
      },
    }));
    return null;
}



function checkForErrors(data){
  let hasError = false;
  for(const name in data){
    let field = data[name];
    if(field.required && field.error && field.error.length > 0) {
      hasError = true;
    }
  }
  return !hasError
}

function clearFormError(name,setter){
  updateFormError(name,"",setter);
}


export default {
  updateFormValue,
  updateFormError,
  checkForErrors,
  clearFormError,
}