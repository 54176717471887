import React from "react";
import api from "../services/categories";

function useCategories() {
  const [category, setCategory] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(false);


  function getCategories(organization){
    setLoading(true);
    api
    .getCategories(organization, (c)=>{
      setLoading(false);
      setCategories(c.categories);
    })

  }

  function getCategory(categoryId){
    setLoading(true);
    api.getCategory(categoryId, (res)=>{
      setCategory(res);
      setLoading(false);
    })
  }
  

  return { category, categories, loading, getCategories, getCategory };
}

export default useCategories;
