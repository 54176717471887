import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth"
import useLocale from "../../hooks/useLocale";


  

function LogoutButton(){
    const auth = useAuth();
    const navigate = useNavigate();
    const {locale} = useLocale();
    const {t} = useTranslation();

    return(
        <Typography variant="footer">
        <button onClick={(e)=>{
            e.preventDefault();
            auth.logout((res)=>{
                //TODO: Replace with global
                if(res) navigate(`/${locale}/login`);
            })
        }}>{t('logout')}</button>
        </Typography>
    )
}

export default LogoutButton