import createAuthRefreshInterceptor from "axios-auth-refresh";

const refreshAuthLogic = (errorHandler) => (failedRequest) =>{}


const refresh = (client, errorHandler, options) => {
  return createAuthRefreshInterceptor(
    client,
    refreshAuthLogic(errorHandler),
    options
  );
};

export default refresh;
