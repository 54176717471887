import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid, Typography } from "@mui/material";

function PaymentButton({ callback, locale, payment }) {

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [scriptLoading, setScriptLoading] = useState(false);
  const [scriptError, setScriptError] = useState(false);
  const [paypal, setPaypal] = useState(null);
  const [rendered, setRendered] = useState(false);

  const getDollars = useCallback(
    (value) => {
      try {
        return payment.showDollarValue(value).substr(1);
      } catch (e) {
        return value;
      }
    },
    [payment]
  );

  useEffect(() => {
    function loadPaypalScript(locale = "en_CA") {
      setScriptLoading(true);
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENTID}&currency=CAD&intent=capture&commit=true&components=buttons`; //&locale=${locale}
      
      script.onload = () => {
        setScriptLoaded(true);
        setScriptLoading(false);
      };
      
      script.onerror = () => {
        window.dataLayer.push({ event: "PayPalButtonError" });
        setScriptLoaded(true);
        setScriptLoading(false);
        setScriptError(true)
      };

      document.body.appendChild(script);
    }

    if (!scriptLoaded && !scriptLoading) loadPaypalScript(locale);
  }, [locale]);

  useEffect(() => {
    if (scriptLoaded) {
      if (typeof window !== "undefined" && window !== undefined)
        setPaypal(window.paypal);
    }
  }, [scriptLoaded]);

  useEffect(() => {
    const {
      payment: { subtotal, tax, discount, total },
    } = payment;

    if (paypal && total > 0) {
      if (rendered) document.getElementById("paypal-btn").innerHTML = "";
      paypal
        .Buttons({
          locale: "en_CA",
          style: {
            size: "responsive",
            height: 55,
            color: "black",
            label: "pay",
            shape: "pill",
            layout: "horizontal",
            tagline: false,
          },

          //https://developer.paypal.com/sdk/js/reference/#link-createorder
          createOrder: (_, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: getDollars(total),
                    currency_code: "CAD",
                    breakdown: {
                      item_total: {
                        currency_code: "CAD",
                        value: getDollars(subtotal + discount),
                      },
                      tax_total: {
                        currency_code: "CAD",
                        value: getDollars(tax),
                      },
                      discount: {
                        currency_code: "CAD",
                        value: getDollars(discount),
                      },
                    },
                  },
                },
              ],
            });
          },

          // Transaction Completed — check status and save payment info
          onApprove: (_, actions) => {
            return actions.order.capture().then((details) => {
              const {
                id: orderId,
                payer: { payer_id: payerId },
                purchase_units,
              } = details;

              const purchaseUnit = purchase_units[0];

              const {
                amount: { value },
              } = purchaseUnit;

              const {
                id: paymentId,
                update_time: paymentTime,
                status,
              } = purchaseUnit.payments.captures[0];

              callback({
                amount: parseInt(parseFloat(value) * 100),
                orderId,
                payerId,
                paymentId,
                paymentTime,
                status,
              });
            });
          },
          onError: (err) => {
            window.dataLayer.push({event: 'PayPalPaymentError', error: err.message})
          },
        })
        .render("#paypal-btn");

      setRendered(true);
    }
  }, [paypal, payment.payment, callback, payment]);

  return (
    <div id="paypal-btn">
      {!scriptLoaded && (
        <Grid container>
          <Grid item xs sx={{textAlign:'center', pt:1}}>
          <CircularProgress size={40} sx={{color:'black'}} />
          </Grid>
        </Grid>
      )}
      {scriptError && (
        <Grid container>
          <Grid item xs sx={{textAlign:'center', pt:1}}>
          <Typography variant="body2" color={'error'}>Sorry, Unkonwn Error Loading PayPal Button</Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

PaymentButton.displayName = "PaymentButton";
PaymentButton.propTypes = {
  amount: PropTypes.string,
  callback: PropTypes.func,
  locale: PropTypes.string,
};

export default PaymentButton;
