import React from "react";
import {Outlet, Navigate, useLocation} from "react-router-dom";
import PreAuthTopBar from "./PreAuthTopBar";
import PreAuthFooter from "../Common/Footer";
import useAuth from "../../hooks/useAuth"
import useLocale from "../../hooks/useLocale"

function PreAuthLayout(){
  
    const auth = useAuth();
    const location = useLocation();
    const {locale} = useLocale()

    if(auth.token && auth.token.length > 0) return <Navigate to={`/${locale}/your-submissions`}/>
    if(location.pathname==='/' || location.pathname==='/login' || location.pathname==='/login/') return <Navigate to={`/en/login`}/>
    else if(location.pathname==='/fr') return <Navigate to={`/fr/login`}/>
    else if(location.pathname!=='')
    return (
        <>
            <PreAuthTopBar />
            <Outlet/>
            <PreAuthFooter />
        </>
        
    )
}


export default PreAuthLayout;


