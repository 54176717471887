export default {
  app: {
    code: process.env.REACT_APP_CODE,
    env: process.env.REACT_APP_ENV,
  },
  api: {
    url: process.env.REACT_APP_API_URL || "localhost:3000",
  },
  SUBMISSION_STEP: {
    INFO: 0,
    DETAILS: 1,
    LOADING: 2,
    DEFAULT: 2,
    ERROR: -1,
  },
};
