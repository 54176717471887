export default {
    bgcolor: 'black',
    color: 'white',
    border: '1px solid black',
    borderRadius: '100px',
    p: '12px 30px',
    textTransform: 'none',
    fontFamily: 'Cabin, sans-serif',
    fontSize: '19.2px',
    '&:hover':{
        background: 'white',
        color: 'black',
        border: 1,
    },
    '&:disabled':{
        bgcolor: '#999999',
        color: '#7C7C7C',
        borderColor: '#888888'
    }
}