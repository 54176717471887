import React from "react";
import { authService } from "../services/auth";
import AuthContext from "../context/auth";
import localStorageUtils from "../utils/localStorage"

function AuthProvider({ children }) {
  let [user] = React.useState(null);
  let [token, setToken] = React.useState(localStorageUtils.findAuth() || null);


  React.useEffect(()=>{

    if(token && token.length){
        //Check if token is valid
        //If valid make sure in localstore
        //If not valid remove from local store and state
    } else {
        localStorageUtils.deleteAuth();
    }
  },[token])

  let login = (data, callback) => {
    try {
      authService.login(data, (res) => {
        if (res.success) {
          const {token, expiry} = res
          setToken(token);
          localStorageUtils.saveAuth({token, expiry});
        } else {
        }
        callback(res);
      });
    } catch (e) {
    }

    return true;
  };

  let logout = (callback) => {
    try{
        authService.logout((res)=>{
            if(res){
                setToken(null)
            }
            callback(res)
        })
        return;
        
    } catch (e) {

    }
  };

  
  let register = (data, callback) => {
    //Anything else needed during registration?
    authService.register(data,callback)
  }

  let resetPassword = (data, callback) => {
    authService.resetPassword(data, callback)
  }


  let changePassword = (data, callback) => {
    authService.changePassword(data, callback)
  }




  let value = { user, token, login, logout, register, resetPassword, changePassword };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
