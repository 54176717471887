export default {
    bgcolor: 'transparent',
    borderRadius: '100px',
    color: 'rgba(0,0,0,0.7)',
    border: '1px solid transparent',
    p: '12px 30px',
    textTransform: 'none',
    fontFamily: 'Cabin, sans-serif',
    fontSize: '19.2px',
    '&:hover':{
        color: 'rgba(0,0,0,1)',
    },
    '&:disabled':{
        color: '#7C7C7C',
    }
}