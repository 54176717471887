import axios from "axios";
import constants from "../utils/constants";
// import authService from "../services/auth";
import refresh from "../utils/refresh";

const client = axios.create({
  baseURL: constants.api.url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-App": constants.app.code,
  },
  withCredentials: true,
});

refresh(
  client,
  () => {

  },
  {
    pauseInstanceWhileRefreshing: true,
  }
);

console.log('CLIENT',client)

export { client };
