import StyledCard from "../Card";
import React from "react";
import styles from "../../styles/PreAuth/Error"

function Error({children, elevation = 2}){
    return(<StyledCard elevation={elevation} styles={styles}>
        {children}
    </StyledCard>)
}

export default Error