import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Trans, useTranslation } from "react-i18next";
import useLocale from "../../hooks/useLocale";
import { Link, useLocation } from "react-router-dom";

function Footer({ align = "center", noPadding = false }) {
  const { t } = useTranslation();
  const { locale, toggleLang } = useLocale();
  const location = useLocation();

  let LanguageSwitcher = ({ locale, pathname, toggleLang }) => {
    let pathnamePart = pathname
      .split("/")
      .slice(2)
      .join("/");
    if (locale === "en")
      return (
        <Link onClick={() => toggleLang("fr")} to={`/fr/${pathnamePart}`}>
          Français
        </Link>
      );
    return (
      <Link onClick={() => toggleLang("en")} to={`/en/${pathnamePart}`}>
        English
      </Link>
    );
  };

  return (
    <Container
      sx={{
        textAlign: align,
        padding: noPadding ? "0 !important" : "15px 25px 20px",
      }}
    >
      {/* TODO Definte and apply footer style */}
      <Typography variant={"footer"}>
        <LanguageSwitcher
          locale={locale}
          pathname={location.pathname}
          toggleLang={toggleLang}
        />
      </Typography>
      <br />
      <Typography variant={"footer"}>
        <Trans i18nKey={"footer.email"}>
          Questions?
          <br />
          <a href="mail-to:staff@magazine-awards.com">
            Click here to contact us
          </a>
        </Trans>
      </Typography>
      <br />
      <Typography variant={"footer"}>{t("footer.copyright")}</Typography>
    </Container>
  );
}
export default Footer;
