import React from "react";
import Box from "@mui/material/Box";

import SideBarCard from "../../Common/SideBar/Card";
import SideBarTable from "../../Common/SideBar/Table";
import SideBarList from "../../Common/SideBar/List";

function SideBar({ data }) {
  return (
    <Box
      sx={{
        height: "100%",
        marginRight: "20px",
      }}
    >
      {data &&
        data.length > 0 &&
        data.map((el, i) => {
          const { variant } = el;
          let { title, rows } = el;
          const key = "sidebar-el-" + i;
          switch (variant) {
            case "list":
              return (
                <SideBarCard title={title} key={key}>
                  <SideBarList rows={rows} />
                </SideBarCard>
              );
            case "table":
              return (
                <SideBarCard title={title} key={key}>
                  <SideBarTable rows={rows} />
                </SideBarCard>
              );
            case "button":
              return (
                <React.Fragment key={key}>
                </React.Fragment>
              );
            default:
              return <React.Fragment key={key}></React.Fragment>;
          }
        })}
    </Box>
  );
}

export default SideBar;
