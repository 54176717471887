import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Global, css } from "@emotion/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeProvider from "./theme";
import Layout from "./Layout";
import PreAuthLayout from "./components/PreAuth/PreAuthLayout";
import PostAuthLayout from "./components/PostAuth/PostAuthLayout";
import AuthProvider from "./providers/auth";
import LocaleProvider from "./providers/locale";
import Login from "./routes/Login";
import Register from "./routes/Register";
import ResetPassword from "./routes/ResetPassword";
import ChangePassword from "./routes/ChangePassword";
import YourSubmissions from "./routes/YourSubmissions";
import SubmissionInfo from "./routes/SubmissionInfo";
import SubmissionDetails from "./routes/SubmissionDetails";
import Cart from "./routes/Cart";
import Payment from "./routes/Payment";
import Invoices from "./routes/Invoices";

import globalStyles from "./styles/global.css";

import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";

const base = `/:locale`;

TagManager.initialize({
  gtmId: 'GTM-T4MCF45',
})


//Exmaple with authenticated/protected routes
//https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx
ReactDOM.render(
  <>
    <CssBaseline />
    <AuthProvider>
      <LocaleProvider>
        <Global styles={css(globalStyles)} />
        <ThemeProvider>
          <BrowserRouter>
            <Routes>
              <Route path={`/`} element={<Layout />}>
                <Route path={`${base}`} element={<PreAuthLayout />}>
                  <Route path={`${base}/login`} element={<Login />} />
                  <Route path={`${base}/register`} element={<Register />} />
                  <Route
                    path={`${base}/reset-password`}
                    element={<ResetPassword />}
                  />
                  <Route
                    path={`${base}/change-password/:token`}
                    element={<ChangePassword />}
                  />
                </Route>
                <Route path={`/`} element={<PostAuthLayout />}>
                  <Route
                    path={`${base}/your-submissions`}
                    element={<YourSubmissions />}
                  />
                  {/* <Route path=`create-submission` element={<Submission newSubmission/>} /> */}
                  <Route path={`${base}/invoices`} element={<Invoices />} />
                  <Route path={`${base}/cart`} element={<Cart />} />
                  <Route path={`${base}/payment`} element={<Payment />} />
                  <Route path={`${base}/submission`}>
                    <Route path={`new`} element={<SubmissionInfo />} />
                    <Route
                      path={`:submissionId`}
                      element={<SubmissionDetails />}
                    />
                    <Route
                      path={`review/:submissionId`}
                      element={<SubmissionDetails review />}
                    />
                  </Route>
                </Route>
              </Route>
              {/* Route path * is unreachable because of ${base} */}
              {/* <Route path={'*'} element={<main style={{ padding: "1rem" }}>
                    <p>There's nothing here</p>
                  </main>} /> */}
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </LocaleProvider>
    </AuthProvider>
  </>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
