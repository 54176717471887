import React from "react";
import Grid from "@mui/material/Grid";
import MainBody from "../MainBody/MainBody";
import SideBar from "./SideBar";


function SideBarLayout(props) {
  const { title, subtext, body, sideBar, success, failure, buttons } = props;

  return (
    <Grid container>
      {sideBar && (
        <Grid item sx={{display: {xs: 'none', md:'block'}}} lg={2}>
          <SideBar data={sideBar} />
        </Grid>
      )}

      <Grid item xs>
        <MainBody
          title={title}
          subtext={subtext}
          success={success}
          failure={failure}
          buttons={buttons}
        >
          {body}
        </MainBody>
      </Grid>
    </Grid>
  );
}

export default SideBarLayout;
