import React from "react";
import { useNavigate } from "react-router-dom";

import SideBarLayout from "../components/PostAuth/SideBarLayout";
import Body from "../components/Submissions/Body";
import useCategories from "../hooks/useCategories";
import useSubmissionData from "../hooks/useSubmissionData";
import constants from "../utils/constants";
import useLocale from "../hooks/useLocale";
import { useTranslation } from "react-i18next";

function NewSubmission() {
  let step = constants.SUBMISSION_STEP.INFO;
  const [disableInput] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(true);
  const [submission, setSubmission] = React.useState({});
  const [organization, setOrganization] = React.useState(null);

  const categories = useCategories();
  const { updateSubmissions, saveSubmission } = useSubmissionData();
  const {locale, toggleLang} = useLocale();
  const navigate = useNavigate();
  const {t} = useTranslation();

  React.useEffect(() => {
    const { organization, category, language } = submission;
    setDisableSubmit(!organization || !category || !language);
  }, [submission]);

  React.useEffect(() => {
    if (organization) {
      categories.getCategories(organization);
    }
  }, [organization]);

  const buttons = (t = ()=>{}) => {
    return [
      {
        type: "secondary",
        label: t('submission.info.button.goBack'),
        disabled: false,
        onClick: () => {
          navigate(-1);
        },
      },
      {
        type: "primary",
        label: t('submission.info.button.next'),
        disabled: disableSubmit,
        onClick: () => {
          if (
            submission.category &&
            submission.organization &&
            submission.language
          ) {
            saveSubmission(submission, (res) => {
              if (!res.error && res.uuid) {
                updateSubmissions(()=>navigate(`/${locale}/submission/${res.uuid}`, { replace: true }))
              } // else setError(res.error) //TODO: setup Submission Error
            });
          }
        },
      },
    ];
  };

  return (
    <SideBarLayout
      title={t('submission.info.title')}
      buttons={buttons(t)}
      body={
        <>
          {/* <Typography variant="console">|disable submit: {disableSubmit?"on":"off"}|</Typography><br/> */}
          <Body
            step={step}
            submission={submission}
            categories={categories}
            disabled={disableInput}
            onChange={(res) => {
              const { value, id } = res;
              if (id === "organization") {
                if (organization !== value) {
                  setOrganization(value);
                  setSubmission((prev) => ({
                    ...prev,
                    organization: { shortName: value },
                    category: undefined,
                  }));
                }
              } else if (id === "category") {
                setSubmission((prev) => ({ ...prev, category: { id: value } }));
              } else if (id === "language") {
                if(value !== locale) toggleLang();
                setSubmission((prev) => ({ ...prev, language: value }));
              }
            }}
          />
        </>
      }
      success={null}
      failure={<p>{t('submission.info.failure')}</p>}
    />
  );
}

export default NewSubmission;
