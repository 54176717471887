import React from "react";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

import SideBarLayout from "../components/PostAuth/SideBarLayout";
import NotFound from "../components/Submissions/NotFound";
import PaymentSuccess from "../components/Payment/PaymentSuccess";
import Callout from "../components/Callout/Callout";

import useCart from "../hooks/useCart";
import useSubmissionData from "../hooks/useSubmissionData";
import useLocale from "../hooks/useLocale";

function Payment() {
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [notFound] = React.useState(false);
  const [sideBar] = React.useState();
  const submissionData = useSubmissionData();
  const cart = useCart();
  const { locale } = useLocale();

  const { submissions, submitPayment } = submissionData;

  function handleSubmitSuccess() {
    submissionData.updateSubmissions(() => {
      setSubmitSuccess(true);
      cart.empty();
    });
  }

  const buttons = cart.freelancer
    ? [
        {
          type: "primary",
          label: "Submit as freelancer",
          disabled: false,
          onClick: () => {
            submitPayment(
              {
                cart,
                lang: locale || "en",
              },
              (success) => {
                if (success) handleSubmitSuccess();
              }
            );
          },
        },
      ]
    : [
        {
          type: "primary",
          label: "Pay by cheque",
          disabled: false,
          hideTop: true,
          onClick: () => {
            submitPayment(
              {
                cart,
                lang: locale || "en",
                cheque: true,
              },
              (success) => {
                if (success) handleSubmitSuccess();
              }
            );
          },
        },
        {
          type: "paypal",
          cart,
          label: "Pay by PayPal",
          disabled: false,
          onClick: (paypalData) => {
            submitPayment(
              {
                paypalData,
                cart,
                lang: locale || "en",
              },
              (success) => {
                if (success) handleSubmitSuccess();
              }
            );
          },
        },
      ];

  if (notFound) return <NotFound />;
  return (
    <SideBarLayout
      title={"Payment"}
      subtext={
        <>
          Review your total and select a method of payment below. Or,{" "}
          <Link to={`/${locale}/cart`}>go back to you cart</Link>.
        </>
      } //TODO: get from dict
      buttons={submitSuccess ? [] : buttons}
      sideBar={sideBar}
      body={
        !submitSuccess && (
          <>
            <TableContainer sx={{ mb: "50px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Submission title</TableCell>
                    <TableCell>Publication</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submissions &&
                    submissions
                      .filter((submission) => cart.hasItem(submission.uuid))
                      .map((submission) => {
                        let priceData = cart.submissionPrice(submission);
                        return (
                          <TableRow key={submission.uuid}>
                            <TableCell>
                              {submission.title || "No title"}
                            </TableCell>
                            <TableCell>
                              {submission.publication || "No publication"}
                            </TableCell>
                            <TableCell>
                              {(submission.category &&
                                submission.category.title) ||
                                "No category"}
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              {cart.showDollarValue(priceData.price)}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                  <TableRow sx={{ borderTop: "2px solid #AAA" }}>
                    <TableCell colSpan={3}>
                      <Typography variant={"body2"}>Subtotal</Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <Typography variant={"body2"}>
                        {cart.showDollarValue(cart.payment.subtotal)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography variant={"body2"}>Tax</Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <Typography variant={"body2"}>
                        {cart.showDollarValue(cart.payment.tax)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ borderBottom: cart.freelancer ? 0 : "inherit" }}
                  >
                    <TableCell colSpan={3}>
                      <Typography variant={"body1"}>Total</Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <Typography variant={"body1"}>
                        {cart.showDollarValue(cart.payment.total)}
                        {cart.freelancer || (cart.smallMagazine && "*")}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {cart.freelancer && (
                    <TableRow sx={{ border: "none" }}>
                      <TableCell colSpan={3}>
                        <Typography variant={"body1"}>Pay today:</Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        <Typography variant={"body1"}>
                          {cart.freelancer && cart.showDollarValue(0)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {cart.smallMagazine && (
                    <TableRow>
                      <TableCell colSpan={4} sx={{ textAlign: "right" }}>
                        <Typography variant={"caption"}>
                          *This total does not include your Small Magazine
                          Rebate.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {(cart.freelancer || cart.smallMagazine) && (
              <Callout elevation={0}>
                {cart.freelancer && (
                  <Typography variant={"body2"}>
                    Freelancer Support Fund - Your application is being reviewed
                    and an invoice will be sent to you at a later date. No
                    payment is collected today.
                  </Typography>
                )}
                {cart.smallMagazine && (
                  <Typography variant={"body2"}>
                    Small Magazine Rebate - Your application is being reviewed
                    and a reimbursement may be sent to you at a later date.
                  </Typography>
                )}
              </Callout>
            )}
          </>
        )
      }
      success={submitSuccess && <PaymentSuccess />}
      failure={<p>Something went wrong</p>}
    />
  );
}

export default Payment;
