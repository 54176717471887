import React from "react";
import Button from "@mui/material/Button";
import styles from '../../styles/Button/PrimaryButton'


function PrimaryButton(props){
    const {children, onClick, disabled} = props
    return(
        <Button type="submit" disabled={disabled} onClick={onClick} sx={styles}>{children}</Button>
    )
}

export default PrimaryButton