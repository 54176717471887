import { client } from "./client";

const submissionsService = {
  getUserSubmissions(callback) {
    client
      .get(`/awards/submission/all`)
      .then((res) => {
        if (res) {
          const { status, data } = res;
          if (status === 200) callback(data);
          else callback([]);
        }

        return;
      })
      .catch((e) =>
        {}
      );
  },

  saveSubmission(submission, callback) {
    client
      .post(`/awards/submission/save`, {
        ...submission,
        category_id:
          (submission && submission.category && submission.category.id) || "",
        org:
          (submission &&
            submission.organization &&
            submission.organization.shortName) ||
          "",
      })
      .then((res) => {
        if (res) {
          const { status, data } = res;
          if (status === 200) callback(data);
          else callback({ error: "Could not save" });
        }
      })
      .catch((e) => {
      });
  },

  submitPayment(
    {
      uuids,
      freelancer,
      smallMagazine,
      amount,
      paymentId,
      orderId,
      paymentTime,
      lang,
      cheque,
    },
    callback
  ) {
    client
      .post("/awards/submission/submit-payment", {
        amount,
        uuids,
        paymentId,
        paymentTime,
        orderId,
        freelancer,
        smallMagazine,
        lang,
        cheque,
      })
      .then((res) => callback(res.status === 200))
      .catch((e) => {
        callback(false);
      });
  },
  deleteUserSubmissions(id,callback) {
    client
      .delete(`/awards/submission/${id}`)
      .then((res) => {
        if (res) {
          const { status, data } = res;
          if (status === 200) callback(data);
          else callback(new Error('Could not delete '+res));
        }

        return;
      })
      .catch((e) =>
        {}
      );
  },
};

export { submissionsService };
