import { client } from "./client";

// const TEMP_ORG={
//   1:'nma',
//   2:'dpa',
//   3:'b2b',
// }

const cateogoriesService = {
  getCategories(organization, callback) {
    client
      .get(`/awards/category/organization`,{params: { organization:organization }})
      .then((res) => {
        if(res){
          const { status, data } = res;
          if (status === 200) callback(data);
          else callback([]);
        }
        
        return;
      })
      .catch((e) =>{}
      );
  },

  getCategory(categoryId, callback){
    client.get('/awards/category/', {params: {categoryId}}).then((res)=>{
        if(res){
          const { status, data } = res;
          if (status === 200) callback(data);
          else callback([]);
        }
        return;
    }).catch((e)=> {})
  }

};

export default cateogoriesService;
