import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import SideBarLayout from "../components/PostAuth/SideBarLayout";
import NotFound from "../components/Submissions/NotFound";
import Body from "../components/Submissions/Body";
import SubmissionInfoSideBar from "../components/Submissions/SideBar/SubmissionInfo";

import useSubmissionData from "../hooks/useSubmissionData";
import useCategories from "../hooks/useCategories";
import useCart from "../hooks/useCart";

import constants from "../utils/constants";
import useLocale from "../hooks/useLocale";
import { useTranslation } from "react-i18next";

function Submission({ newSubmission, review }) {
  let step = constants.SUBMISSION_STEP.DETAILS;

  const [disableInput, setDisableInput] = React.useState(review || false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [submission, setSubmission] = React.useState({});
  const [backup, setBackup] = React.useState(null);
  const [notFound, setNotFound] = React.useState(false);
  const [sideBar, setSideBar] = React.useState();
  const submissionData = useSubmissionData();
  const categories = useCategories();
  const navigate = useNavigate();
  const params = useParams();
  const cart = useCart();
  const { locale } = useLocale();
  const {t} = useTranslation();

  const { submissionId } = params;
  const {
    saveSubmission,
    submissions,
    getSubmissionFromId,
    insertSubmission,
    insertValueIntoFieldSetAtId,
    validateSubmission,
  } = submissionData;

  React.useEffect(() => {
    if (submissionId) {
      let submissionFromId = getSubmissionFromId(submissionId);
      let wasFound = typeof submissionFromId !== "undefined";

      setNotFound(!wasFound);

      if (wasFound) {
        //The submission was found from the URL params
        //Go to step 2, load the submission into state
        //and save a copy of the original
        setSubmission(submissionFromId);
        setBackup(JSON.stringify(submissionFromId));

        console.log({submissionFromId})
        if (submissionFromId.submitted && !review) {
          //If the submission is NOT in draft and the state is not in review
          //navigate to review
          setDisableInput(true);
          navigate(`/${locale}/submission/review/${submissionId}`);
        } else if (!submissionFromId.submitted && review) {
          //If the submission is in draft and not in review
          //navigate to edit
          setDisableInput(false);
          navigate(`/${locale}/submission/${submissionId}`);
        }
      }
    }
  }, [submissions]);

  React.useEffect(() => {
   
    if (submission && submission.uuid) {
      let splitUuid = submission.uuid.split("-");

      const title = "title" + (locale === "fr" ? "_fr" : "");

      setSideBar([
        SubmissionInfoSideBar({
          program: submission.organization && submission.organization[title], //TODO: Replace with actual name
          category: submission.category ? submission.category[title] : "()", //TODO: Replace for FR
          language: submission.language,
          entryId: [splitUuid.at(-2), splitUuid.at(-1)].join("-"),
          updatedAt: submission.updatedAt,
          t
        }),
        
      ]);
    }
  }, [submission, locale, t]);

  const buttons = (review) => {
    if (review) return [];
    return [
      {
        type: "secondary",
        label: "Save draft",
        disabled: disableSubmit,
        onClick: () => {
          saveSubmission(submission, (res) => {
            insertSubmission(res);
          });
        },
      },
      {
        type: "primary",
        label: "Save and add to cart",
        disabled: disableSubmit,
        onClick: () => {
          saveSubmission(submission, (res) => {
            const validatedSubmission = validateSubmission(res);
            insertSubmission(validatedSubmission);
            if (validatedSubmission.validated) {
              cart.addToCart(validatedSubmission.uuid);
              navigate("/your-submissions");
            }
          });
        },
      },
    ];
  };

  if (notFound) return <NotFound />;
  return (
    <SideBarLayout
      title={"Submission details"} //TODO: get from dict
      subtext={"*Required fields"} //TODO: get from dict
      buttons={buttons(review)}
      sideBar={sideBar}
      body={
        <>
          {/* <Typography variant="console" onClick={()=>cart.empty()}>|{JSON.stringify(cart.cart)}|</Typography> */}
          <Body
            step={step}
            submission={submission || {}}
            categories={categories}
            disabled={disableInput}
            onChange={(res) => {
              const { value, id } = res;
              setSubmission((prev) => {
                let { fields } = prev;
                let next = {
                  ...prev,
                  fields: insertValueIntoFieldSetAtId(fields, { value }, id),
                };

                setDisableSubmit(JSON.stringify(next) === backup);
                return next;
              });
            }}
          />
        </>
      }
      success={null}
      failure={<p>Something went wrong</p>}
    />
  );
}

export default Submission;
