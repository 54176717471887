import StyledCard from "../Card";
import React from "react";
import styles from "../../styles/PreAuth/Success"

function Success({children, elevation=2}){
    return(<StyledCard elevation={elevation}  styles={styles}>
        {children}
    </StyledCard>)
}

export default Success