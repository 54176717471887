import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dict_en from "./en.json";
import dict_fr from "./fr.json";

export const languages = {
  en: "en",
  fr: "fr",
};


function InitI18n() {

  try {
    i18n.use(initReactI18next).init({
      resources: {
        [languages.en]: {
          translation: dict_en,
        },
        [languages.fr]: {
          translation: dict_fr,
        },
      },
      lng: languages.en,
      fallbackLng: languages.en,
      interpolation: {
        escapeValue: false,
      },
    });
  } catch (e) {
    return false;
  }

  return true;
}

export default InitI18n;
