import React from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";

function TextField(props) {
  const {
    id,
    name,
    onChange,
    value,
    type,
    label,
    placeholder,
    error,
    disabled,
    subtext,
    required,
    hideErrorLabel,
  } = props;
  return (
    <FormControl fullWidth={true} variant={"outlined"} required={required}>
      {error && !hideErrorLabel && (
        <Typography variant={"error"}>{error}</Typography>
      )}
      <label htmlFor={id}>
        <Typography variant={"label"}>
          {label}
          {required && "*"}
        </Typography>
      </label>
      {subtext && <Typography variant={"body2"}>{subtext}</Typography>}
      <OutlinedInput
        error={error}
        type={type}
        id={id}
        name={name || id}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </FormControl>
  );
}

export default TextField;
