import React from "react";
import {Box} from "@mui/material";
import useLogo from "../../hooks/useLogo";
import { Link } from "react-router-dom";
import useLocale from "../../hooks/useLocale";


function PreAuthTopBar(){
    let logo = useLogo();
    const {locale} = useLocale()

    return(
        <Box position={"static"} sx={{padding: '10px'}}>
            {/* TODO: Replace with dict */}
            {/* TODO: style logo to match */}
            <Link to={`/${locale}/login`}><img width={200} src={logo} alt="National Media Awards Foundation"/></Link>
        </Box>
    )
}

export default PreAuthTopBar