import React from "react";
import CartContext from "../context/cart";
import localStorageUtils from "../utils/localStorage";

import useSubmissionData from "../hooks/useSubmissionData";

const DEFAULT_PAYMENT = {
  subtotal: 0,
  tax: 0,
  discount: 0,
  total: 0,
};

function CartProvider({ children }) {
  let [cart, setCart] = React.useState(localStorageUtils.findCart() || []);
  const [payment, setPayment] = React.useState({ ...DEFAULT_PAYMENT });
  let [freelancer, setFreelancer] = React.useState(false);
  let [smallMagazine, setSmallMagazine] = React.useState(false);

  let submissionData = useSubmissionData()

  React.useEffect(() => {
    if (!localStorageUtils.compareCart(cart)) {
      localStorageUtils.saveCart(cart);
    }

    // if(cart.length){
    //   updateCartBalance(submissionData.submissions)
    // }

  }, [cart]);


  React.useEffect(() => {
    if(cart.length){
      updateCartBalance(submissionData.submissions)
    }
  }, [submissionData]);


  let currencyFormatter = () => {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
      minimumFractionDigits: 2,
    });
  };

  function addToCart(item) {
    if (!hasItem(item)) setCart((prev) => [...prev, item]);
  }

  function removeFromCart(item) {
    setCart((prev) => prev.filter((a) => a !== item));
  }

  function empty() {
    setCart([]);
  }

  const hasItem = (item) => {
    return item && cart.indexOf(item) >= 0;
  };
  const hasItems = () => {
    return cart.length > 0;
  };

  function updateCartBalance(submissions) {
    let cartBalance = { ...DEFAULT_PAYMENT };
    submissions &&
      submissions.forEach((submission) => {
        if (hasItem(submission.uuid)) {
          let priceData = submissionPrice(submission);
          cartBalance.subtotal += priceData.price;
          cartBalance.discount += priceData.discount;
        }
      });



    if (cartBalance.subtotal > 0) {
      cartBalance.tax = taxPrice(cartBalance.subtotal);
      cartBalance.total = cartBalance.subtotal + cartBalance.tax;
    }


    setPayment(cartBalance);
  }

  function checkIsWithinDateRange(submission) {

    try {
      const {
        category: { end, start },
      } = submission;

      const now = Date.now();
      const startDate = new Date(start);
      const endDate = new Date(end);

      return now > startDate && now < endDate;
    } catch (e) {
      return false;
    }
  }

  function checkForEarlyBird(submission) {
    try {
      if (!checkIsWithinDateRange(submission)) return false;

      const {
        category: { discountEndDate },
      } = submission;

      const now = Date.now();
      const earlyBirdDate = new Date(discountEndDate);
      return now < earlyBirdDate;
    } catch (e) {
      return false;
    }
  }

  let submissionPrice = (submission) => {
    const {
      category: { active, discount, price },
    } = submission;

    if (!active) return 0;

    const salePrice = {
      price: checkForEarlyBird(submission) ? discount : price,
      discount: 0,
    };

    salePrice.discount = price - salePrice.price;
    return salePrice;
  };

  let taxPrice = (subtotal) => {
    return Math.round((subtotal * 13) / 100);
  };

  let showDollarValue = React.useCallback(
    (value) => {
      return currencyFormatter().format(value / 100);
    },
    [currencyFormatter]
  );

  const toggleFreelancer = () => {
    return setFreelancer((prev) => {
      if(!prev && smallMagazine) setSmallMagazine(false)
      return(!prev)
    });
  };
  const toggleSmallMagazine = () => {
    setSmallMagazine((prev) => {
      if(!prev && freelancer) setFreelancer(false)
      return(!prev)
    });
    
  };


  let value = {
    cart,
    addToCart,
    removeFromCart,
    empty,
    hasItem,
    hasItems,
    freelancer,
    smallMagazine,
    toggleFreelancer,
    toggleSmallMagazine,
    payment,
    showDollarValue,
    submissionPrice,
    taxPrice,
    updateCartBalance,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

export default CartProvider;
