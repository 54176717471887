import React from "react";
import {Outlet, useParams} from "react-router-dom";
import Box from "@mui/material/Box"
import backgroundImage from "./static/images/submissions-background.png"
import useAuth from "./hooks/useAuth"
import useLocale from "./hooks/useLocale"



//TODO: Move styles to external/global
function Layout() {
  useAuth();
  const params = useParams();
  const { toggleLang, locale } = useLocale();

  React.useEffect(()=>{
    if(typeof params.locale !== 'undefined' && params.locale!==locale) toggleLang(params.locale)
  },[])

  return (
    <Box sx={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundAttachment: 'scroll',
      display: 'block',
      height: '100%'
    }}>
      <Outlet />
    </Box>

  );
}


export default Layout;
