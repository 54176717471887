import React from "react";
import {
  createTheme,
  ThemeProvider as DefaultThemeProvider,
} from "@mui/material/styles";

/**
         * primary - used to represent primary interface elements for a user. It's the color displayed most frequently across your app's screens and components.
secondary - used to represent secondary interface elements for a user. It provides more ways to accent and distinguish your product. Having it is optional.
error - used to represent interface elements that the user should be made aware of.
warning - used to represent potentially dangerous actions or important messages.
info - used to present information to the user that is neutral and not necessarily important.
success - used to indicate the successful completion of an action that user triggered.
         */

const global = {
  palette: {
    info: {
      light: "rgba(119,86,83,0.15)",
      main: "rgba(119,86,83,1)",
      contrastText: "#222222",
    },
    error: {
      light: "rgba(118,26,26,0.15)",
      main: "#BA1A1A",
      contrastText: "#222222",
    },
    success: {
      light: "rgba(33,184,76,0.10)",
      main: "#21B84C",
      contrastText: "#222222",
    },
  },
  typography: {
    fontFamily: {
      notoSans: "Noto Sans, sans-serif",
      cabin: "Cabin, sans-serif",
    },
    body1: {
      fontSize: "18px",
    },
    body2: {
      fontSize: "16px",
    },
    body3: {
      fontSize: "14px",
      color: "#202124"
    },
    navigation: {
      fontSize: "19px",
      color: "#202124"
    },
  },
  components: {
    borderRadius: "15px",
  },
};

const globalTheme = createTheme({
  palette: global.palette,
  typography: {
    fontFamily: global.typography.fontFamily.notoSans,
    h1: {
      fontSize: "30px",
      fontWeight: 500,
      fontFamily: global.typography.fontFamily.cabin,
    },
    h2: {
      fontFamily: global.typography.fontFamily.cabin,
      fontSize: global.typography.body1.fontSize,
      fontWeight: 600,
      marginBottom: "10px"
    },
    h3: {
      fontFamily: global.typography.fontFamily.notoSans,
      fontSize:global.typography.body2.fontSize,
      marginBottom: '5px'
    },
    subtitle1: {},
    body1: {
      fontSize: global.typography.body1.fontSize,
      marginBottom: "10px",
    },
    body2: {
      fontSize: global.typography.body2.fontSize,
      color: "#222",
    },
    body3: {
      fontSize: global.typography.body3.fontSize,
      color: global.typography.body3.color,
    },
    label: {
      fontSize: global.typography.body2.fontSize,
      fontFamily: global.typography.fontFamily.cabin,
      color: '#1C1B1F'
    },
    smallLabel: {
      fontSize: global.typography.body3.fontSize,
      fontFamily: global.typography.fontFamily.cabin,
    },
    footer: {
      fontSize: "16px",
      lineHeight: '22px',
      display: 'inline-block',
      margin: '0 0 10px 0',
      ':last-child':{
        marginBottom:'0'
      }
    },
    error: {
      fontSize: global.typography.body2.fontSize,
      fontWeight: "bold",
      color: "#BA1A1A",
    },
    console:{
      background: 'yellow',
      padding: '3px',
      fontFamily: 'mono, monospace',
      fontSize: '2em',
    },
    button: {},
    caption: {},
    navigation: {
      fontSize: global.typography.navigation.fontSize,
      fontFamily: global.typography.fontFamily.cabin,
      fontWeight: "600",
      textDecoration: "none",
      '&:hover':{
        color: 'rgba(0,0,0,0.75)',
      }
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        style: {
          borderRadius: global.components.borderRadius,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        style: {
          borderRadius: global.components.borderRadius,
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        style: {
          marginBottom: "15px",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        style: {
        //   color: "black",
        },
      },
    },
  },
});

function ThemeProvider({ children }) {
  return (
    <DefaultThemeProvider theme={globalTheme}>{children}</DefaultThemeProvider>
  );
}

export default ThemeProvider;
