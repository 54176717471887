import React from "react";
import { useTranslation } from "react-i18next";
import useLocale from "../../hooks/useLocale";
import RadioInput from "../Common/RadioInput";
import SelectInput from "../Common/SelectInput";

const OrganizationSelect = ({ disabled, value, onChange, lang, t = () => {} }) => (
  <SelectInput
    label={t("submission.info.body.organization.label")}
    name="submission-org"
    disabled={disabled}
    onChange={(props) => {
      props.id = "organization";
      onChange(props);
    }}
    data={[
      {
        title: t("submission.info.body.organization.nma"),
        title_fr: t("submission.info.body.organization.nma"),
        id: "nma",
      },
      {
        title: t("submission.info.body.organization.dpa"),
        title_fr: t("submission.info.body.organization.dpa"),
        id: "dpa",
      },
      {
        title: t("submission.info.body.organization.b2b"),
        title_fr: t("submission.info.body.organization.b2b"),
        id: "b2b",
      },
    ]}
    value={value}
    lang={lang}
  />
);

const CategorySelect = ({
  value,
  data,
  onChange,
  disabled,
  lang,
  t = () => {},
}) => (
  <SelectInput
    label={t("submission.info.body.category.label")}
    name="submission-category"
    onChange={(props) => {
      props.id = "category";
      onChange(props);
    }}
    data={data}
    value={value}
    disabled={disabled || !(data && data.length)}
    lang={lang}
  />
);

const LanguageRadio = ({ value, data, onChange, disabled, t = () => {} }) => (
  <RadioInput
    label={t("submission.info.body.language.label")}
    name="submission-language"
    onChange={(props) => {
      props.id = "language";
      onChange(props);
    }}
    data={data}
    value={value}
    disabled={disabled}
    error={null}
  />
);

function SubmissionInfo(props) {
  const { submission, categories, onChange, disabled } = props;
  const { language, organization, category } = submission || {};
  const { t } = useTranslation();
  const {locale} = useLocale();
  React.useEffect(() => {}, [categories]);
  return (
    <form>
      <OrganizationSelect
        disabled={disabled}
        value={organization && organization.shortName}
        onChange={onChange}
        t={t}
        lang={locale}
      />
      <CategorySelect
        data={categories.categories}
        value={category && category.id}
        onChange={onChange}
        disabled={disabled}
        t={t}
        lang={locale}
      />
      <LanguageRadio
        data={[
          { value: "en", label: t("submission.info.body.language.en") },
          { value: "fr", label: t("submission.info.body.language.fr") },
        ]}
        value={language}
        disabled={disabled}
        onChange={onChange}
        t={t}
      />
    </form>
  );
}

export default SubmissionInfo;
