import React from "react";
import TextField from "../Input/TextField";
import Checkbox from "../Input/Checkbox";
import formUtils from "../../utils/forms";
import { useTranslation } from "react-i18next";

function RegisterForm() {
  const {t} = useTranslation();
  const [formData, setFormData] = React.useState({
    fullName: {
      id: "fullName",
      name: "fullName",
      onChange: (e) =>
        formUtils.updateFormValue("fullName", e.target.value, setFormData),
      value: "",
      type: "text",
      label: t('register.form.fullName.label'),
      placeholder: t('register.form.fullName.placeholder'),
      error: "",
      required: true,
    },
    email: {
      id: "email",
      name: "email",
      onChange: (e) =>
        formUtils.updateFormValue("email", e.target.value, setFormData),
      value: "",
      type: "text",
      label: t('register.form.email.label'),
      placeholder: t('register.form.email.placeholder'),
      error: "",
      required: true,
    },
    password: {
      id: "password",
      name: "password",
      onChange: (e) =>
        formUtils.updateFormValue("password", e.target.value, setFormData),
      value: "",
      type: "password",
      label: t('register.form.password.label'),
      placeholder: t('register.form.password.placeholder'),
      subtext: t('register.form.password.subtext'),
      error: "",
      required: true,
    },
    terms: {
      id: "terms",
      name: "terms",
      onChange: (e) =>
        formUtils.updateFormValue("terms", e.target.checked, setFormData),
      value: false,
      type: "checkbox",
      label: t('register.form.terms.label'),
      error: "",
      required: true,
    },
    subscribe: {
      id: "subscribe",
      name: "subscribe",
      onChange: (e) =>
        formUtils.updateFormValue("subscribe", e.target.checked, setFormData),
      value: "",
      type: "checkbox",
      label: t('register.form.subscribe.label'),
      error: "",
      required: false,
    },
  });

  React.useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      fullName: {
        ...prev.fullName,
        label: t("register.form.fullName.label"),
        placeholder: t("register.form.fullName.placeholder"),
      },
      email: {
        ...prev.email,
        label: t("register.form.email.label"),
        placeholder: t("register.form.email.placeholder"),
      },
      password: {
        ...prev.password,
        label: t("register.form.password.label"),
        placeholder: t("register.form.password.placeholder"),
      },
      terms: {
        ...prev.terms,
        label: t("register.form.terms.label"),
        placeholder: t("register.form.terms.placeholder"),
      },
      subscribe: {
        ...prev.subscribe,
        label: t("register.form.subscribe.label"),
        placeholder: t("register.form.subscribe.placeholder"),
      },
    }));
  }, [t]);

  React.useEffect(() => {}, [formData]);

  return {
    formData,
    setFormData,
    Form: (
      <>
        <TextField {...formData.fullName} />
        <TextField {...formData.email} />
        <TextField {...formData.password} />
        <Checkbox {...formData.terms} />
        <Checkbox {...formData.subscribe} />
      </>
    ),
  };
}

export default RegisterForm;
