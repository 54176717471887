import React from "react";
import { useDropzone } from "react-dropzone";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import useSignedUrl from "../../hooks/useSignedUrl";

import { Box, Grid, Link } from "@mui/material";
import TinyButton from "../Button/TinyButton";

function MediaDrop(props) {
  const {
    id,
    label,
    onChange,
    disableFormSubmit,
    value,
    name,
    required,
    subtext,
    disabled,
    error,
  } = props;

  const { getSignedUploadUrl, upload, loading } = useSignedUrl();
  const [file, setFile] = React.useState({
    url: value || "",
    name: name || "",
    loaded: value && value !== "",
  });

  React.useEffect(()=>{
    if(file.url !== value){
      onChange({value: file.url, id})
    }
  },[file])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    disabled: disabled || false,
    maxSize: Infinity, //TODO: Get max size from API
    minSize: 0,
    maxFiles: 1,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/bmp": [".bmp"],
      "image/gif": [".gif"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        let file = acceptedFiles[0];
        let { name, type } = file;
        getSignedUploadUrl(name, type, ({ url, signedUrl }) => {
          upload(signedUrl, file, () => {
            setFile({ url, type, name });
          });
        });
      }
    },
  });

  const style = React.useMemo(
    () => ({
      border: "2px dashed #cecece",
      borderRadius: "4px",
      padding: "20px",
      margin: "5px 0",
      borderColor: error ? '#BA1A1A' : "#cecece",
      ...(isDragActive ? {} : {}),
      ...(isDragAccept
        ? { backgroundColor: "rgba(33, 184, 76, 0.1)", borderColor: "#21B84C" }
        : {}),
      ...(isDragReject
        ? { backgroundColor: "rgba(186, 26, 26, 0.15)", borderColor: "#BA1A1A" }
        : {}),
    }),
    [isFocused, isDragAccept, isDragReject, isDragActive, error]
  );

  React.useEffect(() => {
    //return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  React.useEffect(()=>{
    if(loading) disableFormSubmit(true)
    else disableFormSubmit(false)
  },[loading])

  return (
    <FormControl fullWidth={true} variant={"outlined"} required={required}>
      {error && <Typography variant={"error"}>{error}</Typography>}
      <label htmlFor={id}>
        <Typography variant={"label"}>{label}</Typography>
      </label>
      {subtext && <Typography variant={"body2"}>{subtext}</Typography>}
      {loading && (
        <Box>
          <span className="icon sprite swirl black rotate-360" />
          <Typography variant={"body2"}> Uploading...</Typography>
        </Box>
      )}
      {!loading && (
        <>
          {file && file.url ? (
            <Grid container columns={2}>
              <Grid item xs>
                <Link target={"_blank"} rel={"noopener"} href={file.url}>
                  <Typography variant={"body1"}>Download your file</Typography>
                </Link>
              </Grid>
              <Grid item>
                {!disabled && <TinyButton
                  onClick={() => {
                    setFile({});
                  }}
                >
                  Replace
                </TinyButton>}
                
              </Grid>
            </Grid>
          ) : (
            <div {...getRootProps({ style })}>
              <input id={id} {...getInputProps()} />

              {isDragAccept && (
                <Typography sx={{ margin: 0 }} variant={"body1"}>
                  Drop files here...
                </Typography>
              )}
              {isDragReject && (
                <Typography sx={{ margin: 0 }} variant={"body1"}>
                  Please make sure you're uploading a PDF or Image (jpg/jpeg,
                  png, bmp, or gif)
                </Typography>
              )}
              {!isDragActive && (
                <Typography sx={{ margin: 0, opacity: disabled ? '0.3' : '1', }} variant={"body1"}>
                  Drop files here...
                </Typography>
              )}
            </div>
          )}
        </>
      )}
    </FormControl>
  );
}

MediaDrop.displayName = "MediaDrop";
MediaDrop.propTypes = {
  lang: PropTypes.string,
  fieldId: PropTypes.string,
  file: PropTypes.array,
  handleFileChange: PropTypes.func,
  disableFormSubmit: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default MediaDrop;
