import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// import styles from "../../../styles/PreAuth/TwoColumnBody/TitleBar";

function Button2(props) {
  const { buttons } = props;

  function getStartButtons(buttons) {
    return buttons.filter(
      (button) => button.position === "start" || button.position === "undefined"
    );
  }

  function getEndButtons(buttons) {
    return buttons.filter((button) => button.position === "end");
  }

  // const getColumnWidth = (buttons) =>
  //   getStartButtons(buttons).length > 0 && getEndButtons(buttons).length
  //     ? 6
  //     : 12;


  const endButtons = getEndButtons(buttons)

  const columnWidth = endButtons.length > 0 ? 1 : 0; //getColumnWidth(buttons);

  return (
    <Box sx={{ textAlign: "left" }}>
      <Grid container columns={2}>
        <Grid xs={columnWidth}>
          {getStartButtons(buttons).map((button) => {
            const { label } = button;
            return (
              <Typography
                sx={{
                  display: "inline-block",
                  fontSize: "body3.fontSize",
                  marginRight: {
                    xs: "10px",
                    ":last-child": {
                      xs: 0,
                    },
                  },
                }}
              >
                {label}
              </Typography>
            );
          })}
        </Grid>
        {endButtons.length > 0 && (
          <Grid xs={columnWidth} sx={{ textAlign: "right" }}>
            {endButtons.map((button) => {
              const { label } = button;
              return (
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "body3.fontSize",
                    // marginRight: {
                    //   xs: "10px",
                    //   ":last-child": {
                    //     xs: 0,
                    //   },
                    // },
                  }}
                >
                  {label}
                </Typography>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Button2;
