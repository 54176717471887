import React from "react";
import DefaultCard from "@mui/material/Card";
import Typography from "@mui/material/Typography";

function Card(params) {
  const { title, children, buttons } = params;

  const Buttons = ({data}) => (
    <>{data.map((row)=>(
        <>
          {row}  
        </>
    ))}</>
  )

  return (
    <DefaultCard sx={{
        padding:'25px',
        mb:'20px',
    }}>
      {title && <Typography sx={{mb:'10px'}} variant={"h3"}>{title}</Typography>}
      {children && children}
      {buttons && buttons.length > 0 && <Buttons>{buttons}</Buttons>}
    </DefaultCard>
  );
}

export default Card;
