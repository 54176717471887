import { useTranslation } from "react-i18next";

function SideBar(data = {}) {
  const { t } = useTranslation();
  const sideBarData = [];

  const { submissions } = data;

  if (submissions) {
    const { cart, draft, paid, nma, dpa, b2b } = submissions;

    sideBarData.push({
      variant: "table",
      title: t("yourSubmissions.sidebar.byStatus.title"),
      rows: [
        {
          label: t("yourSubmissions.sidebar.byStatus.row.inCart"),
          value: cart || 0,
        },
        {
          label: t("yourSubmissions.sidebar.byStatus.row.draft"),
          value: draft || 0,
        },
        {
          label: t("yourSubmissions.sidebar.byStatus.row.submitted"),
          value: paid || 0,
        },
      ],
    });

    let programTableRows = [];
    if (nma > 0)
      programTableRows.push({
        label: t("yourSubmissions.sidebar.byOrganization.row.nma"),
        value: nma,
      });
    if (dpa > 0)
      programTableRows.push({
        label: t("yourSubmissions.sidebar.byOrganization.row.dpa"),
        value: dpa,
      });
    if (b2b > 0)
      programTableRows.push({
        label: t("yourSubmissions.sidebar.byOrganization.row.b2b"),
        value: b2b,
      });

    if (programTableRows.length) {
      sideBarData.push({
        variant: "table",
        title: t("yourSubmissions.sidebar.byOrganization.title"),
        rows: programTableRows,
      });
    }
  }

  return sideBarData;
}

export default SideBar;
